.page_404 {
    padding: 40px 0;
    background: #fff;
    font-family: var(--cormorant-font);
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {
    background: url('../../../public/assets/imgs/bg.gif');
    min-height: 500px;
    background-position: center;

}

.four_zero_four_bg .heading-1 {
    margin-top: -50px;
    font-size: 80px;
}

h3 {
    font-size: 80px;
}

.content_box_404 {
    margin-top: -80px;
}

.content_box_404 .h2 {
    font-family: 'Cormorant', serif;
    font-weight: 600;
    font-size: 20px;
    color: #000000;
}

.content_box_404 .para {
    font-family: 'Cormorant', serif;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
}

.content_box_404 .link {
    background-color: rgb(216, 27, 61, 100%);
    border: none;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-family: 'Cormorant', serif;
    font-weight: 600;
    display: inline-block;
    margin-top: 10px;
}