#StepToFlowSection {
    background: var(--myGray);
    min-height: 70dvh;
    height: fit-content;
    width: 100%;
    background-size: 100vh;
}

#StepToFlowSection>.myContainer>h1 {
    color: #000000;
    text-transform: capitalize;
    padding: 0 10px;
    position: relative;
    width: fit-content;
    margin: 0 auto;
    font-size: 36px;
    margin-bottom: 12px;
    font-family: 'Cormorant', serif;
}

#StepToFlowSection>.myContainer>h6 {
    color: var(--myRed);
    text-transform: capitalize;
    padding: 0 10px;
    position: relative;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 12px;
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

#StepToFlowSection>.myContainer>h6::before,
#StepToFlowSection>.myContainer>h6::after {
    position: absolute;
    background-color: var(--myRed);
    height: 70%;
    display: block;
    top: 55%;
    transform: translateY(-50%);
    content: '';
    padding: 1px;
}

#StepToFlowSection>.myContainer>h6::before {
    right: 0;
}

#StepToFlowSection>.myContainer>h6::after {
    left: 0;
}

.cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Three columns layout */
    gap: 1rem;
    padding: 20px;
}

.InternationalStudents {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 420px;
}

.InternationalStudents>figure {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--myRed);
    width: 90px;
    height: 90px;
    margin: 0;
}

.InternationalStudents>figure>img {
    width: 60%;
    height: 60%;
}

.image-11 .image-55 {
    width: 100%;
    height: 50vh;
    object-fit: cover;
}

.InternationalStudents .content {
    background-color: white;
    padding: 20px 10px;
    width: 100%;
    height: 70%;
    transition: all .4s;
    position: relative;
    margin-top: 25px;
}

.InternationalStudents .num {
    font-family: 'Cormorant', serif;
    font-size: 28px;
    font-weight: bold;
    color: var(--myRed);
}

.InternationalStudents .title {
    font-size: 22px;
    font-weight: bold;
    margin: 10px 0;
    text-align: center;
}

.InternationalStudents .description {
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    text-align: center;
    color: gray;
    transition: all .4s;
}

.InternationalStudents .content i {
    position: absolute;
    clip-path: polygon(48% 20%, 0% 100%, 100% 100%);
    background-color: #fff;
    padding: 10px;
    top: -18px;
    opacity: 0;
    transition: all .1s;
    opacity: 1;
}

.InternationalStudents .content:hover {
    background-color: var(--myRed);
    cursor: pointer;
}

.InternationalStudents .content:hover i {
    background-color: var(--myRed);
    opacity: 1;
}

.InternationalStudents .content:hover .num,
.InternationalStudents .content:hover .title,
.InternationalStudents .content:hover .description {
    color: white;
}
.bd-crum{
  margin-top: 100px;
}
/* Responsive adjustments */
@media (max-width: 991px) {
    .cards-container {
        grid-template-columns: repeat(2, 1fr);
        /* Two columns for tablet size */
    }
}

@media (max-width: 767px) {
    .cards-container {
        grid-template-columns: repeat(1, 1fr);
        /* Single column for mobile size */
    }
}