.breadcrumb__wrapper1 {
    padding: 40px 0px;
}

.breadcrumb__menu1 {
    margin: 40px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.breadcrumb__menu1 p:first-child {
    color: #fff !important;
    font-size: 36px;
    font-weight: 600;
    font-family: 'Cormorant', serif;
}

.breadcrumb__menu1 p:last-child {
    color: #fff !important;
    font-size: 22px;
    font-weight: 600;
    font-family: 'Cormorant', serif;
    padding-top: 30px;
    text-align: center;
}