
#ServicesSection {
    background: var(--myGray) url(../../../public/assets/imgs/services/dots.png) top right no-repeat;
    min-height: 70dvh;
    height: fit-content;
    width: 100%;
}

#ServicesSection>.myContainer>h1 {
    color: #000000;
    text-transform: capitalize;
    padding: 0 10px;
    position: relative;
    width: fit-content;
    margin: 0 auto;
    font-size: 36px;
    margin-bottom: 12px;
    font-family: 'Cormorant', serif;
}

#ServicesSection>.myContainer>h6 {
    color: var(--myRed);
    text-transform: capitalize;
    padding: 0 10px;
    position: relative;
    width: fit-content;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 12px;
    font-family: 'Rubik', sans-serif;
}

#ServicesSection>.myContainer>h6::before,
#ServicesSection>.myContainer>h6::after {
    position: absolute;
    background-color: var(--myRed);
    height: 70%;
    display: block;
    top: 55%;
    transform: translateY(-50%);
    content: '';
    padding: 1px;
}

#ServicesSection>.myContainer>h6::before {
    right: 0;
}

#ServicesSection>.myContainer>h6::after {
    left: 0;
}

.myContainer {
    max-width: 1920px;
}

.services-card-wrapper>div>h1 {
    font-family:'Cormorant', serif;
    font-size: 28px;
}

.services-card-wrapper>div {
    background-color: white;
    padding: 30px;
    padding-top: 60px;
    position: relative;
    width: 360px;
    min-height: 210px;
}

.services-card-wrapper>div>p {
    font-family: "DM Sans", sans-serif;
    color: var(--myGray);
}

.services-card-wrapper>div>div>.link {
    font-family: "DM Sans", sans-serif;
    color: var(--myRed);
    text-decoration: none;
    width: fit-content;
    font-weight: 600;
}

.services-card-wrapper>div>div>img {
    width: 22px;
}

.services-card-wrapper>div>figure {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--myRed);
    position: absolute;
    top: -30px;
    left: 20px;
}

.services-card-wrapper>div>figure>img {
    width: 65%;
    height: 65%;
}