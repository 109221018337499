#StepToFlowSection2 {
    background: #fff;
    min-height: 70dvh;
    height: fit-content;
    width: 100%;
    background-size: 100vh;
}

#StepToFlowSection2>.myContainer>h1 {
    color: #000000;
    text-transform: capitalize;
    padding: 0 10px;
    position: relative;
    width: fit-content;
    margin: 0 auto;
    font-size: 36px;
    margin-bottom: 12px;
    font-family: 'Cormorant', serif;
}

#StepToFlowSection2>.myContainer>h6 {
    color: var(--myRed);
    text-transform: capitalize;
    padding: 0 10px;
    position: relative;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 12px;
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

#StepToFlowSection2>.myContainer>h6::before,
#StepToFlowSection2>.myContainer>h6::after {
    position: absolute;
    background-color: var(--myRed);
    height: 70%;
    display: block;
    top: 55%;
    transform: translateY(-50%);
    content: '';
    padding: 1px;
}

#StepToFlowSection2>.myContainer>h6::before {
    right: 0;
}

#StepToFlowSection2>.myContainer>h6::after {
    left: 0;
}

.StepToFlowSection-card1 {
    width: 340px;
    height: 360px;
}

.StepToFlowSection-card1>div {
    background-color: rgb(255,242,231,100%);
    padding: 20px 10px;
    width: 100%;
    height: 70%;
    transition: all .4s;
    position: relative;
    margin-top: 25px;
    cursor: pointer;
}

.StepToFlowSection-card1 .num {
    font-family:'Cormorant', serif;
    font-size: 45px;
    font-weight: bold;
    color: var(--myRed);;
}

.StepToFlowSection-card1>div>i {
    position: absolute;
    clip-path: polygon(48% 20%, 0% 100%, 100% 100%);
    background-color: rgb(255,242,231,100%);
    padding: 10px;
    top: -18px;
    opacity: 0;
    transition: all .1s;
    opacity: 1;
}




.StepToFlowSection-card>div>p {
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    color: gray;
    transition: all .4s;
    margin-top: -10px;
    font-family: 400;
}




