/************ TABLE OF CONTENTS ***************

1	THEME DEFAULT CSS START	
2	TYPOGRAPHY CSS START	
3	BOOTSTRAP CUSTOMIZE	
4	COMMON CLASSES CSS START	
5	MFP CUSTOMIZE	
6	BODY OVERLAY	
7	OFFCANVAS CSS START	
8	SECTION TITLE CSS START	
9	SPACE CSS START	
10	BACKTOTOP CSS START	
11	ANIMATION CSS START	
12	PRELOADER CSS START	
13	SEARCH CSS START	
14	MENU CSS START	
15	HEADER CSS START	
16	BUTTON CSS START	
17	FOOTER CSS START	
18	BANNER CSS START	
19	VISA CATEGORIES CSS START	
20	CONSULTING FIRM CSS START	
21	TEXT SLIDER SECTION CSS START	
22	VIDEO SECTION CSS START	
23	CHOOSE COUNTRY SECTION CSS START	
24	SERVICES SECTION CSS START	
25	TESTIMONIALS SECTION CSS START	
26	BRAND SECTION CSS START	
27	COACHING SECTION CSS START	
28	BENEFITS SECTION CSS START	
29	TEAM SECTION CSS START	
30	FAQ SECTION CSS START	
31	BLOG SECTION CSS START	
32	BANNER 3 SECTION CSS START	
33	VISA 3 SECTION CSS START	
34	COUNTRY LIST 3 SECTION CSS START	
35	CTA 3 SECTION CSS START	
36	WORK PROCESS SECTION CSS START	
37	CHOOSE SECTION CSS START	
38	IMAGE 3 SECTION CSS START	
39	FAQ 3 SECTION CSS START	
40	TRAINING SECTION CSS START	
41	TESTIMONIALS 3 SECTION CSS START	
42	HOME 4 CSS START	
43	BANNER 4 CSS START	
44	COACHING 4 CSS START	
45	FIRM CSS START	
46	COUNTRY LIST 3 CSS START	
47	ICON COUNTER BOX CSS START	
48	APPLY ONLINE AREA CSS START	
49	CHOOSE 4 AREA CSS START	
50	BOOKING AREA CSS START	
51	DARK HOME CSS START	
52	ABOUT PAGE CSS START	
53	TEAM DETAILS PAGE CSS START	
54	STORY DETAILS PAGE CSS START	
55	GALLERY PAGE CSS START	
56	FAQ PAGE CSS START	
57	404 PAGE CSS START	
58	COACHING DETAILS CSS START	
59	CONTACT PAGE CSS START	
60	BLOG DETAILS CSS START	
61	VISA DETAILS CSS START	
62	COUNTRY DETAILS CSS START		

**********************************************/

/*----------------------------------------*/
/*  01. THEME DEFAULT CSS START
/*----------------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

/* Styles for mobile screens */

.main-menu ul li.has-dropdown>a::after,
.main-menu ul li .submenu,
.main-menu ul li .mega-menu,
.main-menu ul li .mega-menu li ul li a::before,
.vw-search-area,
.mean-container .mean-nav ul li>a>i,
.mean-container .mean-nav ul li a.mean-expand.mean-clicked i,
.offcanvas__contact-icon i,
.offcanvas__overlay,
.fill-btn.has-border i,
a,
.btn,
button,
span,
svg,
p,
input,
select,
textarea,
li,
img,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6,
i[class^=flaticon-],
.body__overlay {
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}

/* variables */

:root {
  --cormorant-font: 'Cormorant', serif;
  --rubik-font: 'Rubik', sans-serif;
  /* colors */
  --theme-color: #D81B3D;
  --color-1: #D81B3D;
  --black: #1C263A;
  --black-1: #222222;
  --dark: #151515;
  --white: #ffffff;
  --gray: #5D666F;
  --gray-1: #D9D9D9;
  --gray-2: #5D666F;
  --border: #E0E0E0;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/*---------------------------------
    TYPOGRAPHY CSS START
---------------------------------*/
body {
  font-family: var(--rubik-font);
  font-size: 16px;
  font-weight: normal;
  color: var(--gray);
  line-height: 26px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--cormorant-font);
  color: var(--black);
  margin-top: 0px;
  line-height: 1.3;
  margin-bottom: 0;
  font-weight: 700;
}

h1 {
  font-size: 80px;
}

h2 {
  font-size: 70px;
}

h3 {
  font-size: 60px;
}

h4 {
  font-size: 30px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: var(--gray);
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus {
  text-decoration: none;
}

a,
button {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}

figure {
  margin: 0;
}

/* img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
} */

button:hover {
  cursor: pointer;
}

button:focus {
  outline: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

hr:not([size]) {
  margin: 0;
  border-color: rgba(22, 22, 22, 0.1);
  opacity: 1;
  border-width: 1px;
}

input[type=text],
input[type=email],
input[type=tel],
input[type=number],
input[type=password],
input[type=time],
.nice-select,
textarea {
  outline: none;
  width: 100%;
  font-size: 16px;
  color: var(--gray);
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  border-top: none;
  border-right: none;
  border-left: none;
}

*::-moz-selection {
  background: #151515;
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  background: #151515;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #151515;
  color: #fff;
  text-shadow: none;
}

*::-moz-placeholder {
  color: #AFAFAF;
  opacity: 1;
  font-size: 16px;
}

*::placeholder {
  color: #AFAFAF;
  opacity: 1;
  font-size: 16px;
}

.Datepicker-wrapper {
  padding-right: 37%
}

/*----------------------------------------
   BOOTSTRAP CUSTOMIZE
-----------------------------------------*/
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  /* --bs-gutter-x: 3rem; */
  padding-left: 24px;
  padding-right: 24px;
}

/* .row {
  --bs-gutter-x: 3rem;
} */

.large-container-right {
  max-width: 1595px;
  margin: 0 0 0 auto;
  padding: 0 15px;
}

@media only screen and (max-width: 1440px) {

  .medium-container {
    max-width: 1000px;
  }

}


/*---------------------------------
  COMMON CLASSES CSS START
---------------------------------*/

.page-wrapper {
  position: relative;
  margin: 0 auto;
  overflow: hidden !important;
  width: 100%;
  min-width: 300px;
}

.w-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.m-img img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.z-index-1 {
  z-index: 1;
}

.z-index-11 {
  z-index: 11;
}

.overflow-y-visible {
  overflow-x: hidden;
  overflow-y: visible;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.include-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hr-1 {
  border-top: 1px solid rgb(232, 232, 232);
}

.x-clip {
  overflow-x: clip;
}

.bg-color-1 {
  background: #F2EDEB;
}

.bg-color-2 {
  background: rgb(216, 27, 61, 100%);
}

.bg-color-3 {
  background: #1C263A;
}

.bg-color-4 {
  background: #F8F8F8;
}

.bg-color-5 {
  background: #F5f5f5;
}

.bg-color-6 {
  background: #F2F2F2;
}

.bg-color-7 {
  background: var(--theme-color);
}

.bg-color-8 {
  background: #0f0f0f;
}

.bg-color-9 {
  background: rgb(216, 27, 61, 100%);
}

.bg-color-10 {
  background: rgba(225, 56, 51, 0.1);
}

.bar_line {
  position: absolute;
  top: 0;
  left: 105px;
  width: 1px;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  background: #00000026;
}

/*----------------------------------------
   MFP CUSTOMIZE
-----------------------------------------*/
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 1280px;
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .mfp-iframe-holder .mfp-content {
    max-width: 1000px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .mfp-iframe-holder .mfp-content {
    max-width: 850px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mfp-iframe-holder .mfp-content {
    max-width: 820px;
  }
}

.mfp-close {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.mfp-close:hover {
  color: #fff;
}

.mfp-close::after {
  position: absolute;
  content: "\f00d";
  height: 100%;
  width: 100%;
  font-family: "Font Awesome 6 Pro";
  font-size: 31px;
  font-weight: 200;
  right: -20px;
  margin-top: -25px;
}

@media (max-width: 575px),
only screen and (min-width: 576px) and (max-width: 767px),
only screen and (min-width: 768px) and (max-width: 991px) {
  .mfp-close::after {
    right: 15px;
    margin-top: -30px;
  }

  .sidebar__toggle {
    display: inline;
  }

}

.body__overlay {
  background-color: #151515;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

.body__overlay {
  background-color: #151515;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

.body__overlay.opened {
  opacity: 0.7;
  visibility: visible;
}

.bd-sticky {
  position: sticky;
  top: 120px;
}

/*----------------------------------------
  BODY OVERLAY
-----------------------------------------*/
.body-overlay {
  background-color: #151515;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}

.body-overlay.opened {
  opacity: 0.7;
  visibility: visible;
}

/*----------------------------------------*/
/* OFFCANVAS CSS START
/*----------------------------------------*/
.offcanvas__close button {
  height: 45px;
  width: 45px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  background-color: var(--theme-color);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.offcanvas__close button i {
  color: #fff;
}

.offcanvas__info {
  background: #191919 none repeat scroll 0 0;
  border-left: 2px solid var(--theme-color);
  position: fixed;
  right: 0;
  top: 0;
  width: 400px;
  height: 100%;
  -webkit-transform: translateX(calc(100% + 80px));
  -moz-transform: translateX(calc(100% + 80px));
  -ms-transform: translateX(calc(100% + 80px));
  -o-transform: translateX(calc(100% + 80px));
  transform: translateX(calc(100% + 80px));
  -webkit-transition: transform 0.45s ease-in-out, opacity 0.45s ease-in-out;
  -moz-transition: transform 0.45s ease-in-out, opacity 0.45s ease-in-out;
  transition: transform 0.45s ease-in-out, opacity 0.45s ease-in-out;
  z-index: 999;
  overflow-y: scroll;
  overscroll-behavior-y: contain;
  scrollbar-width: none;
}

.offcanvas__info ::-webkit-scrollbar {
  display: none;
}

@media (max-width:450px) {
  .offcanvas__info {
    width: 300px;
  }
}

.offcanvas__info.info-open {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.offcanvas__logo a img {
  width: 150px;
}

.offcanvas__wrapper {
  position: relative;
  height: 100%;
  padding: 30px 30px;
}

@media (max-width: 575px) {
  .sidebar__toggle {
    display: inline;
  }

  .offcanvas__wrapper {
    padding: 20px;
  }
}

.offcanvas__top {
  padding-bottom: 20px;
}

.offcanvas__search {
  position: relative;
}

.offcanvas__search input {
  width: 100%;
  height: 50px;
  line-height: 40px;
  padding-right: 20px;
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid #CECECE;
  font-size: 14px;
  color: #fff;
  padding-left: 0;
}

.offcanvas__search input::placeholder {
  color: #fff;
}

.offcanvas__search input:focus {
  border-color: #fff;
}

.offcanvas__search button {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 16px;
  color: #fff;
}

.offcanvas__search button:hover {
  color: var(--theme-color);
}

.offcanvas__text p {
  margin-bottom: 25px;
}

.offcanvas__contact h4 {
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 600;
  color: var(--white);
}

.offcanvas__contact ul li:not(:last-child) {
  margin-bottom: 20px;
}

.offcanvas__contact ul li:hover i {
  color: var(--white);
  border-color: var(--theme-color);
  background-color: var(--theme-color);
}

.offcanvas__contact-icon i {
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  border: 1px solid var(--clr-border-1);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  color: #fff;
  position: relative;
  z-index: 5;
}

.offcanvas__contact-text a {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.offcanvas__contact-text a:hover {
  color: var(--theme-color);
}

.offcanvas__social {
  margin-bottom: 30px;
}

.offcanvas__social ul li {
  display: inline-block;
}

.offcanvas__social ul li:not(:last-child) {
  margin-right: 5px;
}

.offcanvas__social ul li a {
  display: inline-block;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  color: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  z-index: 5;
  background-color: transparent;
}

.offcanvas__social ul li a:hover {
  color: var(--white);
  border: 1px solid var(--theme-color);
  background-color: var(--theme-color);
}

.offcanvas__notification-icon a {
  font-size: 14px;
  color: #fff;
  position: relative;
}

.offcanvas__notification-icon a .notification-count {
  position: absolute;
  top: -4px;
  right: -13px;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 22px;
  font-size: 12px;
  font-weight: 600;
  background-color: var(--theme-color);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  color: #fff;
  text-align: center;
}

.offcanvas__notification-text p {
  margin-bottom: 0;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
}

.offcanvas__notification-text p .notification-number {
  color: var(--theme-color);
}

.offcanvas__notification-text p a {
  color: var(--theme-color);
}

.offcanvas__overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #151515;
  z-index: 900;
  top: 0;
  opacity: 0;
  visibility: hidden;
  right: 0;
}

.offcanvas__overlay.overlay-open {
  opacity: 0.8;
  visibility: visible;
}

.sidebar__toggle {
  cursor: pointer;
  color: var(--black);
}

.sidebar__toggle:hover {
  color: var(--theme-color);
}


.offcanvas__contact-icon {
  margin-inline-end: 15px;
}

.offcanvas__input input {
  background: rgba(118, 118, 130, 0.3);
  border: transparent;
  height: 60px;
  width: 100%;
  padding-left: 20px;
  padding-right: 80px;
  color: var(--white);
}

.offcanvas__input button {
  height: 60px;
  width: 60px;
  background-color: var(--theme-color);
  color: var(--white);
  font-size: 16px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 5px 5px 0;
}

/*----------------------------------------*/
/*  SECTION TITLE CSS START
/*----------------------------------------*/
.section-title {
  font-size: 48px;
  line-height: 60px;
  font-weight: 700;
  text-transform: capitalize;
}

.section-sub-title {
  font-family: var(--rubik-font);
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  text-transform: capitalize;
  color: var(--theme-color);
}

.img1 {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.left11 {
  padding: 30px 50px 30px 50px;
}

.map {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pakistan {
  text-align: center;
  font-size: 24px;
  color: #000000;
  font-family: 'Cormorant', serif;
}

.immigria {
  text-align: center;
  font-size: 22px;
  color: #000000;
  font-family: 'Cormorant', serif;
}

.zafar {
  text-align: center;
  font-size: 20px;
  color: #000000;
  font-family: 'Cormorant', serif;
  font-weight: 600;
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .section-title {
    font-size: 36px !important;
    line-height: 46px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .section-title {
    font-size: 32px !important;
    line-height: 42px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title {
    font-size: 28px !important;
    line-height: 38px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title {
    font-size: 28px !important;
    line-height: 38px;
  }

  .section-title br {
    display: none;
  }

  .section-sub-title {
    font-size: 18px !important;
    line-height: 28px;
  }

}

@media (max-width: 575px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title {
    font-size: 28px !important;
    line-height: 38px;
  }

  .section-title br {
    display: none;
  }

  .section-sub-title {
    font-size: 18px !important;
    line-height: 28px;
  }

  .sidebar__toggle {
    display: inline;
  }
}

@media (max-width:450px) {

  .section-title {
    font-size: 28px !important;
    line-height: 36px;
  }

  .section-sub-title {
    font-size: 18px !important;
    line-height: 28px;
  }

  .sidebar__toggle {
    display: inline;
  }
}

/*----------------------------------------*/
/*  SPACE CSS START
/*----------------------------------------*/

.section-space {
  padding-top: 40px;
  background: rgb(243, 243, 243, 100%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-space {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .section-space {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .sidebar__toggle {
    display: inline;
  }
}

.section-space-top {
  padding-top: 120px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-space-top {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space-top {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .section-space-top {
    padding-top: 70px;
  }

  .sidebar__toggle {
    display: inline;
  }
}

.section-space-bottom {
  padding-bottom: 120px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-space-bottom {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space-bottom {
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .sidebar__toggle {
    display: inline;
  }

  .section-space-bottom {
    padding-bottom: 70px;
  }
}

.section-space-medium {
  padding: 100px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-space-medium {
    padding: 80px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space-medium {
    padding: 60px 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .section-space-medium {
    padding: 60px 0;
  }
}

.sidebar__toggle {
  display: inline;
}

.section-space-medium-top {
  padding-top: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-space-medium-top {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space-medium-top {
    padding-top: 60px;
  }
}

@media (max-width: 575px),
only screen and (min-width: 576px) and (max-width: 767px) {
  .section-space-medium-top {
    padding-top: 60px;
  }
}

.sidebar__toggle {
  display: inline;
}

.section-space-medium-bottom {
  padding-bottom: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-space-medium-bottom {
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space-medium-bottom {
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .section-space-medium-bottom {
    padding-bottom: 60px;
  }
}

.sidebar__toggle {
  display: inline;
}

.section-space-small {
  padding: 80px 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-space-small {
    padding: 80px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space-small {
    padding: 60px 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .section-space-small {
    padding: 60px 0;
  }
}

.sidebar__toggle {
  display: inline;
}

.section-space-small-top {
  padding-top: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space-small-top {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px),
(max-width: 575px) {
  .section-space-small-top {
    padding-top: 60px;
  }

  .sidebar__toggle {
    display: inline;
  }
}


/*----------------------------------------*/
/*  BACKTOTOP CSS START
/*----------------------------------------*/
.backtotop-wrap {
  position: fixed;
  bottom: 35px;
  right: 30px;
  height: 44px;
  width: 44px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
  color: #151515;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}

.backtotop-wrap:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
}

.backtotop-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.backtotop-wrap::after {
  position: absolute;
  font-family: "Font Awesome 6 Pro";
  content: "\f062";
  text-align: center;
  line-height: 44px;
  font-size: 16px;
  font-weight: 900;
  left: 0;
  top: 0;
  height: 44px;
  width: 44px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}

.backtotop-wrap svg path {
  fill: none;
}

.backtotop-wrap svg.backtotop-circle path {
  stroke: #ccc;
  stroke-width: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}



/*----------------------------------------*/
/* ANIMATION CSS START
/*----------------------------------------*/
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

/*======================================
Animate slide-left 
========================================*/
@keyframes slide-left {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  to {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

/*======================================
Animate card-bounce
========================================*/
@keyframes card-bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

@keyframes hero-circle-1 {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.quote-animation {
  animation-duration: 10s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: card-bounce;
}

/*======================================
Animate wobble-vertical
========================================*/
@-webkit-keyframes wobble-vertical {
  16.65% {
    transform: translateY(8px);
  }

  33.3% {
    transform: translateY(-6px);
  }

  49.95% {
    transform: translateY(4px);
  }

  66.6% {
    transform: translateY(-2px);
  }

  83.25% {
    transform: translateY(1px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes wobble-vertical {
  16.65% {
    transform: translateY(8px);
  }

  33.3% {
    transform: translateY(-6px);
  }

  49.95% {
    transform: translateY(4px);
  }

  66.6% {
    transform: translateY(-2px);
  }

  83.25% {
    transform: translateY(1px);
  }

  100% {
    transform: translateY(0);
  }
}

/*======================================
Animate wobble-vertical
========================================*/
@keyframes shape-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotateCircle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotateCircle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotate-circle {
  -webkit-animation-name: rotateCircle;
  animation-name: rotateCircle;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@keyframes circle {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}


@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes slide {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 1920px 0;
  }
}

@-webkit-keyframes slide {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 1920px 0;
  }
}


@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

@keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

@keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%
  }

  25% {
    border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%
  }

  50% {
    border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
  }

  75% {
    border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;
  }
}


@keyframes aspro-scroller {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}

@keyframes aspro-scroller-reverse {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(100%, 0);
  }
}


/*----------------------------------------*/
/*  PRELOADER CSS START
/*----------------------------------------*/

.preloader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: var(--color-1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.preloader h1 {
  position: relative;
  color: rgba(255, 255, 255, .3);
  font-size: 5em;
  letter-spacing: .01em;
  line-height: 1em;
  text-transform: uppercase
}

@media(max-width:575.98px) {
  .sidebar__toggle {
    display: inline;
  }

  .preloader h1 {
    font-size: 1.5em
  }
}

.preloader h1:before {
  content: attr(data-text);
  position: absolute;
  margin-top: 2px;
  max-width: 120%;
  white-space: nowrap;
  overflow: hidden;
  color: var(--theme-color);
  -webkit-animation: loading 5s linear infinite;
  animation: loading 5s linear infinite;
  font-family: var(--title-font)
}

.preloader h1:after {
  position: absolute;
  right: 0;
  bottom: -10px;
  content: "Loading...";
  font-size: 18px;
  line-height: 1em;
  color: var(--theme-color);
  font-weight: 500;
  letter-spacing: .005em
}

@media(max-width:575.98px) {
  .sidebar__toggle {
    display: inline;
  }

  .preloader h1:after {
    font-size: 14px
  }
}

@-webkit-keyframes loading {
  0% {
    max-width: 0
  }
}

@keyframes loading {
  0% {
    max-width: 0
  }
}


/*----------------------------------------*/
/*  MEAN MENU CSS START
/*----------------------------------------*/
/* Mean menu customize */
.mean-container a.meanmenu-reveal {
  display: none;
}

.mean-container .mean-nav {
  background: none;
  margin-top: 0;
}

.mean-container .mean-bar {
  padding: 0;
  min-height: auto;
  background: none;
}

.mean-container .mean-nav>ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
  display: block !important;
}

.mean-container a.meanmenu-reveal {
  display: none !important;
}

.mean-container .mean-nav ul li a {
  width: 100%;
  padding: 10px 0;
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  text-transform: capitalize;
  border-top: 1px solid #CECECE;
}

.mean-container .mean-nav ul li a:last-child {
  border-bottom: 0;
}

.mean-container .mean-nav ul li a:hover {
  color: var(--theme-color);
}

.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 5px;
  padding: 0 !important;
  height: 30px;
  width: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  line-height: 30px;
  top: 0;
  font-weight: 400;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.mean-container .mean-nav ul li>a>i {
  display: none;
}

.mean-container .mean-nav ul li>a.mean-expand i {
  display: inline-block;
}

.mean-container .mean-nav>ul>li:first-child>a {
  border-top: 0;
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked i {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
}

.mean-container .mean-nav ul li .mega-menu li a {
  height: 200px;
  width: 100%;
  padding: 0;
  border-top: 0;
  margin-bottom: 20px;
}

/*----------------------------------------*/
/*  SEARCH CSS START
/*----------------------------------------*/
.vw-search-area {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  background-color: #fff;
  padding: 40px 15px;
  visibility: visible;
  opacity: 0;
  transform: translateY(-120%);
}

.vw-search-area.opened {
  transform: translateY(0%);
  visibility: visible;
  opacity: 1;
}

.vw-search-input {
  position: relative;
}

.vw-search-input button {
  position: absolute;
  top: 54%;
  right: 20px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 24px;
}

.vw-search-input button:hover {
  color: var(--theme-color);
}

.vw-search-category span {
  color: var(--black);
}

.vw-search-category a {
  font-size: 14px;
  margin-left: 5px;
}

.vw-search-category a:hover {
  color: var(--primary-color);
}

.vw-search-close {
  display: none;
}

.vw-search-close-btn {
  border-radius: 50%;
  background: #ECECEC;
  border: 9px solid transparent;
  color: var(--black);
  width: 36px;
  height: 36px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.vw-search-close-btn::after,
.vw-search-close-btn::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 90%;
  top: 46%;
  left: 1px;
  transform-origin: 50% 50%;
  background-color: var(--black);
  opacity: 1;
  -moz-transition: -moz-transform ease 0.25s;
  -webkit-transition: -webkit-transform ease 0.25s;
  -o-transition: -o-transform ease 0.25s;
  -ms-transition: -ms-transform ease 0.25s;
  transition: transform ease 0.25s;
}

.vw-search-close-btn::before {
  transform: rotate(45deg);
}

.vw-search-close-btn::after {
  transform: rotate(-45deg);
}

.vw-search-close-btn:hover::before {
  transform: rotate(-45deg);
}

.vw-search-close-btn:hover::after {
  transform: rotate(45deg);
}

/*----------------------------------------*/
/*  MENU CSS START
/*----------------------------------------*/
.main-menu ul {
  margin-bottom: 0;
}

.main-menu ul li {
  position: relative;
  list-style: none;
  display: inline-block;
  margin-inline-end: 70px;
}

.main-menu ul li:last-child {
  margin-inline-end: 0;
}

.main-menu ul li a {
  font-family: var(--cormorant-font);
  font-weight: 700;
  display: inline-block;
  font-size: 20px;
  color: #000000;
  padding: 30px 0;
  text-align: left;
  position: relative;
}

.main-menu ul li.has-dropdown>a {
  position: relative;
}

/* .main-menu ul li.has-dropdown > a::after {
  content: "\f078";
  transform: translateY(-1px);
  font-size: 10px;
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
  margin-inline-start: 5px;
  display: inline-block;
} */
.main-menu ul li.has-mega-menu {
  position: static;
}

.main-menu ul li .submenu {
  position: absolute;
  top: 100%;
  inset-inline-start: 0;
  min-width: 240px;
  background: #fff;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transform-origin: top center;
  color: var(--theme-color);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.main-menu ul li .submenu li {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}

.main-menu ul li .submenu li.has-dropdown>a::after {
  position: absolute;
  top: 50%;
  inset-inline-end: 25px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #151515;
}

.main-menu ul li .submenu li a {
  position: relative;
  z-index: 11;
  color: var(--gray);
  font-weight: 700;
  font-size: 18px;
  padding: 0 25px;
  padding-bottom: 11px;
  padding-top: 11px;
  width: 100%;
  border-bottom: 1px solid #eeeeee;
}

.main-menu ul li .submenu li:last-child a {
  border: none;
}

.main-menu ul li .submenu li .submenu {
  inset-inline-start: 100%;
  top: 0;
  visibility: hidden;
  opacity: 0;
}

.main-menu ul li .submenu li:hover>a {
  background: #ECECEC;
  color: var(--theme-color);
}

.main-menu ul li .submenu li:hover>a::after {
  color: #fff;
}

.main-menu ul li .submenu li:hover>.submenu {
  -webkit-transform: translateY(1);
  -moz-transform: translateY(1);
  -ms-transform: translateY(1);
  -o-transform: translateY(1);
  transform: translateY(1);
  visibility: visible;
  opacity: 1;
}

.main-menu ul li .mega-menu {
  position: absolute;
  top: 100%;
  left: 0;
  padding: 40px 225px;
  background: #fff;
  box-shadow: 0px 20px 30px rgba(3, 4, 28, 0.1);
  visibility: hidden;
  opacity: 0;
  transform-origin: top center;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  z-index: 99;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-menu ul li .mega-menu {
    padding: 40px 150px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu ul li .mega-menu {
    padding: 25px 25px;
    padding-inline-start: 80px;
  }
}

.main-menu ul li .mega-menu li {
  float: left;
  width: 25%;
  margin-inline-end: 0;
  text-align: left;
}

[dir=rtl] .main-menu ul li .mega-menu li {
  float: right;
  margin-left: 0;
  text-align: right;
}

.main-menu ul li .mega-menu li .mega-menu-title {
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #151515;
  cursor: text;
  padding: 0;
  display: inline-block;
  margin-bottom: 15px;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 5px;
}

.main-menu ul li .mega-menu li .mega-menu-title::before {
  display: none;
}

.main-menu ul li .mega-menu li ul li {
  width: 100%;
  float: none;
  margin-bottom: 5px;
}

.main-menu ul li .mega-menu li ul li:last-child {
  margin-bottom: 0;
}

.main-menu ul li .mega-menu li ul li a {
  font-weight: 500;
  font-size: 15px;
  color: #5D636A;
  padding: 0;
  position: relative;
}

.main-menu ul li .mega-menu li ul li a::before {
  position: absolute;
  content: "";
  left: 0;
  right: auto;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0;
  height: 1.2px;
  background-color: var(--primary-color);
}

[dir=rtl] .main-menu ul li .mega-menu li ul li a::before {
  right: 0;
  left: auto;
}

.main-menu ul li .mega-menu li ul li:hover>a {
  color: var(--primary-color);
}

.main-menu ul li .mega-menu li ul li:hover>a::before {
  background-color: var(--primary-color);
}

.main-menu ul li .mega-menu li:hover>.mega-menu-title {
  color: var(--primary-color);
  padding-inline-start: 0;
}

.main-menu ul li .mega-menu li:hover>a {
  color: var(--primary-color);
}

.main-menu ul li .mega-menu li a:hover {
  padding-inline-start: 18px;
}

[dir=rtl] .main-menu ul li .mega-menu li a:hover {
  padding-inline-start: 18px;
  padding-inline-end: 0px;
}

.main-menu ul li .mega-menu li a:hover::before {
  width: 12px;
}

/* .main-menu ul li:hover.has-dropdown > a::after {
  content: "\f078";
} */
.main-menu ul li:hover>.submenu {
  visibility: visible;
  opacity: 1;
}

.main-menu ul li:hover .mega-menu {
  visibility: visible;
  opacity: 1;
}

.main-menu-2 ul li a {
  color: #fff;
  position: relative;
}

.main-menu-2 ul li:hover>a {
  color: #fff;
}

.main-menu-2 ul li:hover>a::after {
  color: #fff;
}


/*----------------------------------------*/
/*  HEADER CSS START
/*----------------------------------------*/

.header-top .container-fluid {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.header-top .socials-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 250px;
  padding: 10px 95px;
  clip-path: polygon(4% 0%, 100% 0, 100% 100%, 0% 100%);
}

.header-top .header-info {
  padding: 10px 95px;
}

.header-top .header-info ul {
  margin-top: 2px;
  gap: 30px;
}

.header-info ul li {
  font-size: 12px;
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-info ul li i {
  font-size: 20px;
  margin-right: 10px;
  color: var(--theme-color);
}

.header-info ul li:before {
  content: "|";
  position: relative;
  top: 0;
  right: 15px;
  color: #0000001A;
}

.header-info ul li:first-child:before {
  display: none;
}

.header-one .socials-info .one {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  gap: 30px;
}


.header-one .socials-info .one li:before {
  content: "/";
  position: relative;
  top: 0;
  right: 15px;
  color: #FFFFFF33;
}

.header-one .socials-info .one li:first-child:before {
  display: none;
}

.header-one .socials-info .two {
  color: #fff;
  gap: 10px;
  font-size: 22px;
}


.header-area {
  position: relative;
  z-index: 1;
  overflow-x: clip;
}

.header-one .mega-menu-wrapper.mega-menu-wrapper {
  padding: 0 95px;
}

.header-one .header-logo img {
  width: 100%;
}

.header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 80px;
}

.header-right {
  gap: 60px;
}

.header-call-us {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
}

.header-call-us .contact-num span {
  font-size: 14px;
  line-height: 24px;
  color: var(--color-1);
}

.header-call-us .contact-num h5 {
  font-size: 24px;
  line-height: 34px;
  color: var(--color-1);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-left {
    gap: 45px;
  }
}

.sticky {
  position: fixed !important;
  top: 0;
  z-index: 111;
  inset-inline-end: 0;
  inset-inline-start: 0;
  width: 100%;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  background: #fff;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
  -moz-box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
  box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
}

.search-toggle-open.header-search .search-icon {
  color: var(--black);
  font-size: 20px;
}

.search-toggle-open.header-search .search-icon:hover {
  color: var(--theme-color);
}

.search-toggle-open.header-search {
  cursor: pointer;
}

.vw-search-input input[type="text"] {
  background: var(--theme-color);
  color: var(--white);
}

.vw-search-input input[type="text"]::placeholder {
  color: var(--white);
}

.vw-search-input button {
  color: var(--white);
}

.vw-search-input button:hover {
  color: var(--black);
}

@media only screen and (max-width: 1750px) {

  .header-top .header-info {
    padding: 10px 0 10px 95px;
  }

  .header-top .socials-info {
    gap: 150px;
  }

  .main-menu ul li {
    margin-inline-end: 35px;
  }

  .header-right {
    gap: 30px;
  }

  .header-left {
    gap: 40px;
  }

}

@media only screen and (max-width: 1440px) {

  .header-top .header-info {
    padding-left: 40px;
  }

  .header-top .socials-info {
    padding: 10px 40px;
  }

  .header-one .mega-menu-wrapper.mega-menu-wrapper {
    padding: 0 40px;
  }

  .header-call-us {
    gap: 10px;
  }

  .header-call-us .contact-num h5 {
    font-size: 18px;
    line-height: 28px;
  }

  .header-call-us .icon-box svg {
    width: 45px;
  }

  .main-menu ul li a {
    font-size: 16px;
  }

  .main-menu ul li {
    margin-inline-end: 30px;
  }

  .header-top .socials-info {
    gap: 80px;
  }

}

@media only screen and (max-width: 1199px) {

  .header-top {
    display: none;
  }

}

@media only screen and (max-width: 991px) {

  .header-one .mega-menu-wrapper.mega-menu-wrapper {
    padding: 20px;
  }

}

/* Header Two */

.header-two .header-main {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  border-bottom: 1px solid #00000026;
}

.header-two .main-menu ul li a {
  padding: 40px 0;
}

.header-two .header__hamburger {
  position: absolute;
  top: 45px;
  left: 45px;
}

@media only screen and (max-width: 1820px) {

  .header-two .header-main {
    justify-content: end;
    padding-right: 50px;
  }

}

@media only screen and (max-width: 1380px) {

  .header-two .header-main {
    gap: 25px;
  }

  .header-two .header-left {
    gap: 30px;
  }

  .header-tw .header-right {
    gap: 15px;
  }

  .header-two .header-main {
    padding-right: 20px;
  }

}

@media only screen and (max-width: 1199px) {

  .header-two .header-main {
    justify-content: center;
    padding-right: 0px;
  }

}

@media only screen and (max-width: 991px) {

  .header-two .header-main {
    padding: 20px;
    border: 0;
  }

  .header-two .header__hamburger {
    position: absolute;
    top: 30px;
    left: 45px;
  }

}

/*----------------------------------------*/
/*  BUTTON CSS START
/*----------------------------------------*/

.primary-btn-1 {
  color: var(--white);
  font-size: 14px;
  font-weight: 400;
  background-color: var(--color-1);
  display: inline-flex;
  align-items: center;
  line-height: 24px;
  white-space: nowrap;
  padding: 15px 30px;
  gap: 10px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 600ms ease
}

.primary-btn-1 span {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: var(--theme-color);
  transition: all .6s;
  transform: translate(-50%, -50%);
  z-index: -1
}

.primary-btn-1:hover {
  color: var(--white);
}

.primary-btn-1:hover span {
  width: 225%;
  height: 1000.5px
}

/* button style two */

.primary-btn-2 {
  color: var(--black);
  font-family: var(--cormorant-font);
  border: 1px solid #5D666F33;
  border-radius: 5px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  line-height: normal;
  white-space: nowrap;
  padding: 15px 20px;
  gap: 10px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all .6s
}

.primary-btn-2 span {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: rgb(216, 27, 61, 100%);
  transition: all .6s;
  transform: translate(-50%, -50%);
  z-index: -1
}

.primary-btn-2:hover {
  color: var(--white);
}

.primary-btn-2:hover span {
  width: 225%;
  height: 1000.5px
}

/* button style three */

.primary-btn-3 {
  color: var(--white);
  font-family: var(--rubik-font);
  border: 1px solid var(--white);
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  line-height: normal;
  white-space: nowrap;
  padding: 15px 25px;
  gap: 10px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all .6s
}

.primary-btn-3 span {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: var(--black);
  transition: all .6s;
  transform: translate(-50%, -50%);
  z-index: -1
}

.primary-btn-3:hover {
  color: var(--white);
}

.primary-btn-3:hover span {
  width: 225%;
  height: 1000.5px
}

.primary-btn-3 i {
  font-size: 10px;
}

/* button style four */

.primary-btn-4 {
  color: var(--white);
  font-size: 14px;
  font-weight: 400;
  background-color: var(--theme-color);
  display: inline-flex;
  align-items: center;
  line-height: 24px;
  white-space: nowrap;
  padding: 15px 30px;
  gap: 10px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  transition: all 600ms ease
}

.primary-btn-4 span {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: var(--color-1);
  transition: all .6s;
  transform: translate(-50%, -50%);
  z-index: -1
}

.primary-btn-4:hover {
  color: var(--white);
}

.primary-btn-4:hover span {
  width: 225%;
  height: 1000.5px
}

/*----------------------------------------*/
/*  FOOTER CSS START
/*----------------------------------------*/

.footer-widget-1 *,
.footer-widget-2 *,
.footer-widget-3 *,
.footer-widget-4 * {
  color: var(--white);
}

.footer-widget-1 ul.contact-info li i {
  color: var(--theme-color);
  font-size: 20px;
}

.footer-widget-1 ul.contact-info li a {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 10px;
  gap: 20px;
}

.footer-widget-1 ul.socials-info {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 15px;
  font-size: 30px;
}

.footer-widget-1 ul.socials-info li a i {
  transition: all 700ms ease;
}

.footer-widget-2 ul li:hover,
.footer-widget-1 ul.contact-info li a:hover,
.footer-widget-1 ul.socials-info li:hover a i {
  color: var(--theme-color);
}

.footer-widget-2,
.footer-widget-3,
.footer-widget-4 {
  margin-top: 65px;
}

.fw-title {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 34px;
}

.footer-widget-2 ul li {
  margin-bottom: 25px;
}

.footer-widget-2 ul li:last-child {
  margin-bottom: 0;
}

.footer-widget-3 h5 {
  font-size: 24px;
  line-height: 34px;
}

.footer-widget-3 span {
  color: var(--theme-color);
}

.footer-widget-4 .footer-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.footer-widget-4 .image-area i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
  color: var(--white);
  opacity: 0;
}

.footer-widget-4 .image-area:hover i {
  opacity: 1;
}

.footer-widget.two .image-area img {
  width: 100%;
}

.footer-widget-4 .image-area:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.55) 17.19%, #E20935 100%);
  transition: all 500ms ease;
}

.footer-widget-4 .image-area:hover:before {
  width: 100%;
  left: 0;
}

.footer-bottom {
  border-top: 1px solid #FFFFFF26;
}

.footer-bottom h6 {
  color: var(--white);
  font-family: var(--rubik-font);
  font-weight: 400;
}

.footer-bottom h6 a:hover {
  color: var(--theme-color);
}

.footer-main .shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: left;
}

@media only screen and (max-width: 991px) {

  .footer-widget-2 {
    padding-left: 0;
  }

}


/*----------------------------------------*/
/*  BANNER CSS START
/*----------------------------------------*/

.banner-section {
  padding: 200px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.banner-section .map-shape {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.banner-section .bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(225, 56, 51, 0.8) 0%, rgba(29, 40, 81, 0.36) 102.55%);
}

.banner-flag {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.banner-container {
  max-width: 710px;
}

.banner-sub-title {
  padding: 15px 30px;
  background: var(--color-1);
  display: inline-block;
  font-size: 14px;
  color: var(--white);
}

.banner-title {
  font-size: 100px;
  line-height: 110px;
  color: var(--white);
  margin: 30px 0;
}

.banner-container .icon-box {
  font-size: 60px;
  color: var(--white);
  position: absolute;
  top: 110px;
  right: 20px;
  transition: all 900ms ease;
}

.banner-container:hover .icon-box {
  right: 0;
  transform: rotate(30deg);
}

.banner-section .shape-1 {
  position: absolute;
  top: 30px;
  left: 10px;
  animation: shake 30s linear infinite;
}

@media only screen and (max-width: 1399px) {

  .banner-title {
    font-size: 70px;
    line-height: 80px;
  }

  .banner-container {
    max-width: 550px;
  }

  .banner-section .map-shape {
    width: 300px;
  }

}

@media only screen and (max-width: 991px) {

  .banner-container {
    max-width: 550px;
    margin: 0 auto;
  }

  .banner-section {
    padding: 150px 0 120px 0;
  }

  .banner-section .map-shape {
    display: none;
  }

}

@media only screen and (max-width: 575px) {
  .sidebar__toggle {
    display: inline;
  }

  .banner-container .icon-box {
    display: none;
  }

  .banner-title {
    font-size: 42px;
    line-height: 52px;
  }

  .banner-section {
    padding: 90px 0;
  }

}

/*----------------------------------------*/
/*  VISA CATEGORIES CSS START
/*----------------------------------------*/

.visa-categories-section.is-white * {
  color: var(--white);
}

.visa-categories-section .section-sub-title span {
  color: var(--theme-color);
}

.visa-cat-box .icon-box {
  border-bottom: 1px solid #FFFFFF33;
}

.visa-cat-box .icon-box i {
  font-size: 50px;
}

.visa-cat-box .box-1 {
  display: inline-block;
  transition: all 700ms ease;
}

.visa-cat-box:hover .box-1 {
  transform: rotate3d(0, 4, .2, 3rad);
}

.visa-cat-box .icon-box h6 {
  font-family: var(--rubik-font);
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 20px;
}

.visa-cat-box h4 {
  text-transform: capitalize;
}

.visa-cat-box h4:hover a {
  color: var(--theme-color);
}

.visa-cat-box .icon-box:before {
  content: "\e909";
  position: absolute;
  bottom: -1px;
  left: -1px;
  font-size: 10px;
  transition: all 2500ms ease;
}

.visa-cat-box:hover .icon-box:before {
  left: 90%;
}

.visa-cat-box a.visa-btn {
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  text-transform: uppercase;
}

.visa-cat-box a.visa-btn:before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  border-bottom: 1px dashed #FFFFFF33;
  height: 100%;
  width: 98px;
}

.visa-cat-box:hover a.visa-btn span,
.visa-cat-box:hover a.visa-btn i {
  color: var(--theme-color) !important;
}

.visa-cat-box:hover a.visa-btn:before {
  border-bottom: 1px dashed var(--theme-color);
}

.visa-categories-section .shape-1 {
  position: absolute;
  top: 0;
  right: 0;
}

.visa-categories-section .shape-2 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.visa-categories-section .map-shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top;
  background-size: auto;
}

@media only screen and (max-width: 1440px) {

  .visa-categories-section .shape-1,
  .visa-categories-section .shape-2 {
    width: 200px;
  }

}

/*----------------------------------------*/
/*  CONSULTING FIRM CSS START
/*----------------------------------------*/

.consulting-firm-section .shape-1 {
  position: absolute;
  bottom: 153px;
  right: 0;
}

.consulting-firm-section:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 71%;
  height: 82%;
}

.wptb-radial-progress {
  display: inline-block;
  background: var(--white);
  box-shadow: 0px 4px 20px 0px #BFBFBF40;
  padding: 25px 20px 40px 20px;
  border-radius: 10px;
  position: absolute;
  bottom: -20px;
  left: -70px;
}

.wptb-radial-progress h6 {
  font-size: 18px;
  margin-bottom: 15px;
}

.flags span {
  font-size: 12px;
  margin-left: 60px;
  color: var(--theme-color);
}

.flags img {
  position: absolute;
  left: 0;
  top: 0;
}

.flags img.two {
  left: 10px;
  z-index: 1;
}

.flags img.three {
  left: 24px;
  z-index: 1;
}

.con-firm-icon {
  position: absolute;
  top: -30px;
  right: 90px;
  background: var(--theme-color);
  width: 107px;
  height: 107px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.con-firm-icon i {
  font-size: 45px;
  color: var(--color-1);
  background: var(--white);
  border: 5px solid var(--white);
  border-radius: 50%;
}

/* Radial Progress */

svg.radial-progress {
  height: auto;
  max-width: 85px;
  padding: 0;
  transform: rotate(-90deg);
  width: 100%;
  background: transparent;
  border-radius: 50%;
}

.wptb-radial-progress .wptb-item--inner svg.radial-progress circle {
  fill: transparent;
  stroke: rgba(var(--theme-color), 0.05);
  stroke-width: 7px;
}

.wptb-radial-progress .wptb-item--inner svg.radial-progress circle.bar--animated {
  stroke-dasharray: 219.91148575129;
  stroke: var(--theme-color);
  stroke-dashoffset: 219.91148575129;
  stroke-width: 7px;
  stroke-linecap: round;
}

.sidebar__toggle {
  display: none;
}

.wptb-radial-progress .wptb-item--inner svg.radial-progress text {
  fill: var(--color-1);
  text-anchor: middle;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  font-family: var(--cormorant-font);
}

/* Radial Progress End*/

.con-firm-content .icon-box-area .icon-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.con-firm-content .icon-box-area .icon-box i {
  font-size: 45px;
  color: var(--theme-color);
  transition: all 700ms ease;
}

.con-firm-content .icon-box-area p {
  font-family: var(--rubik-font);
}

.con-firm-content .content-area {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 30px;
}

.con-firm-content .icon-box-area .icon-box:hover i {
  color: var(--color-1);
}

.con-firm-content .icon-box-area .icon-box:hover h4 {
  color: var(--theme-color);
}

@media only screen and (max-width: 1399px) {

  .con-firm-content .icon-box-area .icon-box {
    margin-bottom: 10px;
  }

  .con-firm-content .content-area {
    margin-bottom: 20px;
  }

  .con-firm-content .icon-box-area .icon-box h4 {
    font-size: 20px;
    line-height: 30px;
  }

  .con-firm-content .icon-box-area .icon-box i {
    font-size: 32px;
  }

  .consulting-firm-section .shape-1 {
    bottom: 134px;
  }

}

@media only screen and (max-width: 1199px) {

  .consulting-firm-section:before,
  .consulting-firm-section .shape-1 {
    display: none;
  }

}

@media only screen and (max-width: 1199px) {

  .con-firm-icon,
  .wptb-radial-progress {
    display: none;
  }

  .con-firm-image {
    padding-right: 0;
    padding-bottom: 30px;
  }

}

@media only screen and (max-width: 1199px) {

  .con-firm-content .content-area {
    align-items: flex-start;
    flex-direction: column;
  }

}

/*----------------------------------------*/
/*   TEXT SLIDER SECTION CSS START
/*----------------------------------------*/

.text-slider-box {
  display: flex;
  position: relative;
  animation: aspro-scroller-reverse 60s linear infinite;
  will-change: transform;
  white-space: nowrap;
  gap: 55px;
  left: -100%;
}

.text-slider-box .slide-box {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 55px;
  text-transform: uppercase;
}

.text-slider-box .slide-box:nth-child(even) h1 {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--theme-color);
  color: transparent;
}

.style-2 .text-slider-box .slide-box:nth-child(even) h1 {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--black);
  color: transparent;
}

@media only screen and (max-width: 425px) {

  .text-slider-section {
    display: none;
  }

}

.text-slider-box.style-2 .slide-box h2 {
  font-size: 180px;
  letter-spacing: 8rem;
  line-height: 118px;
}

.text-slider-box.style-2 .slide-box h2 span {
  color: var(--theme-color);
}

.text-slider-box .slide-box:nth-child(even) h2 {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--black);
  color: transparent;
}

.text-slider-box.style-2 .slide-box:nth-child(even) h2 span {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--theme-color);
  color: transparent;
}

/*----------------------------------------*/
/*   VIDEO SECTION CSS START
/*----------------------------------------*/

.video-section {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 210px 0 400px 0;
  text-align: center;
}

.video-section:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(225, 56, 51, 0.6) 34.56%, rgba(255, 255, 255, 0) 101.02%);
}

.video-section .play-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-left: 255px;
}

.video-section .play-btn a {
  width: 150px;
  height: 150px;
  background: var(--white);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--cormorant-font);
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all 700ms ease;
}

.video-section .play-btn a:hover {
  background: var(--black);
  color: var(--white);
}

.video-section .play-content {
  text-align: left;
  margin-bottom: 130px;
}

.video-section .play-content i {
  font-size: 58px;
  color: var(--white);
}

.video-section .play-content h5 {
  font-size: 24px;
  line-height: 34px;
  color: var(--white);
  text-transform: capitalize;
}

.video-section .play-content .one {
  font-size: 36px;
  color: var(--theme-color);
}

.video-section .play-content .two {
  font-size: 36px;
  color: var(--black);
}

.video-section .play-content h5:last-child {
  color: var(--black);
}

.video-section .shape-1 {
  position: absolute;
  top: 0;
  right: 0;
}

.video-section .shape-2 {
  position: absolute;
  bottom: 0;
  left: 0;
}

/*----------------------------------------*/
/*   CHOOSE COUNTRY SECTION CSS START
/*----------------------------------------*/

.choose-country {
  margin-top: -370px;
}

.choose-country * {
  color: var(--white);
}

.choose-country:before {
  content: "";
  position: absolute;
  top: 0;
  right: -15px;
  width: 150px;
  height: 100%;
  background: var(--theme-color);
}

.choose-country-box {
  display: flex;
  max-width: 680px;
  justify-content: start;
  align-items: flex-start;
  gap: 25px;
  padding: 35px 40px;
  background: var(--black);
  border: 1px solid #FFFFFF26
}

.choose-country-box h5 {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 10px;
}

.choose-country-box .bg-image {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 700ms ease;
}

.choose-country-box:hover .bg-image {
  top: 0;
  height: 100%;
}

.choose-1-active {
  max-height: 540px;
}

.choose-slider-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: rotate(-90deg);
  font-size: 70px;
  gap: 60px;
  height: 20px;
  margin-top: 30px;
  margin-bottom: 0;
}

.choose-right-box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  right: -60px;
  z-index: 2;
}

.text-box .text-1 {
  margin-top: 70px;
  font-size: 100px;
  line-height: 110px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--white);
  color: transparent;
}

.choose-left-content {
  padding: 75px;
}

.choose-right-content {
  margin-left: -40px;
}


@media only screen and (max-width: 1550px) {

  .large-container-right {
    max-width: 1200px;
  }

  .choose-left-content {
    padding: 0 15px;
  }

  .choose-country-box {
    max-width: 500px;
    gap: 20px;
    padding: 25px 35px;
  }

  .choose-right-content {
    margin-left: 0;
  }

  .video-section .shape-2 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 250px;
  }

  .text-box .text-1 {
    margin-top: 30px;
    font-size: 80px;
    line-height: 90px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--white);
    color: transparent;
  }

  .choose-country:before {
    content: "";
    position: absolute;
    top: 0;
    right: -15px;
    width: 95px;
    height: 100%;
    background: var(--theme-color);
  }

}


@media only screen and (max-width: 1399px) {

  .video-section {
    padding: 130px 0 350px 0;
  }

  .choose-1-active {
    max-height: 430px;
  }

  .choose-country-box {
    padding: 20px;
    gap: 15px;
  }

  .choose-country-box p {
    font-size: 14px;
    line-height: 25px;
  }

  .video-section .shape-2 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 199px;
  }

}

@media only screen and (max-width: 1299px) {

  .large-container-right {
    max-width: 1100px;
  }

  .text-box .text-1 {
    font-size: 45px;
    line-height: 55px;
    margin-top: 20px;
  }

  .choose-right-box {
    right: 0;
  }

  .choose-country:before {
    width: 65px;
  }

  .choose-slider-navigation {
    font-size: 40px;
    gap: 30px;
    margin-top: 75px;
  }

  .choose-left-content {
    margin-top: 50px;
  }

  .video-section .shape-2 {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 165px;
  }

}

@media only screen and (max-width: 1159px) {

  .choose-country:before,
  .choose-right-box {
    display: none;
  }

  .choose-left-content {
    margin-top: 0;
  }


}

@media only screen and (max-width: 991px) {

  .choose-1-active {
    max-height: 320px;
  }

  .choose-country-box {
    max-width: 100%;
  }

  .video-section .play-box {
    gap: 10px;
    margin-left: 0;
    flex-direction: column;
  }

  .video-section .play-content {
    text-align: center;
    margin-bottom: 30px;
  }

  .video-section {
    padding-top: 150px;
  }

}

@media only screen and (max-width: 560px) {

  .choose-1-active {
    max-height: 500px;
  }

  .choose-left-content {
    padding: 0;
    padding-bottom: 30px;
  }

  .choose-country-box {
    flex-direction: column;
  }

}

@media only screen and (max-width: 510px) {

  .choose-1-active {
    max-height: 550px;
  }

  .choose-country-box {
    padding: 25px;
  }

}

.choose-c-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

/*----------------------------------------*/
/*   SERVICES SECTION CSS START
/*----------------------------------------*/

/* custom-mouse-pointer */

.mouse-pointer {
  position: fixed;
  top: 50%;
  left: -100px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  pointer-events: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 9999;
  -webkit-transition-property: width, height, background;
  -o-transition-property: width, height, background;
  transition-property: width, height, background;
  -webkit-transition-duration: .9s;
  -o-transition-duration: .9s;
  transition-duration: .9s;
  -webkit-transition-timing-function: cubic-bezier(.19, .94, .336, 1);
  -o-transition-timing-function: cubic-bezier(.19, .94, .336, 1);
  transition-timing-function: cubic-bezier(.19, .94, .336, 1);
  border-radius: 50%;
  background: var(--theme-color);
  overflow: hidden;
}

.mouse-pointer.display_none {
  display: none;
}

body.out .mouse-pointer {
  width: 0;
  height: 0;
}

.mouse-pointer.large {
  width: 100px;
  height: 100px;
  background: var(--theme-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mouse-pointer.large h5 {
  color: var(--white);
}

/* custom-mouse-pointer-end */

.services-box {
  padding: 15px 25px 15px 15px;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 285px auto;
  align-items: center;
  gap: 45px;
}

.services-box .icon-box-top {
  font-size: 45px;
  color: var(--theme-color);
  margin-bottom: 30px;
}

.services-box .content h4 {
  margin-bottom: 15px;
}

.services-box .content h4:hover a {
  color: var(--theme-color);
}

.services-box .content .service-btn {
  position: relative;
  font-size: 18px;
  font-family: var(--cormorant-font);
  font-weight: 700;
  color: var(--color-1);
}

.services-box .service-btn:before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 93px;
  height: 0;
  border-bottom: 1px dashed #5D666F;
}

.services-box .content .service-btn i {
  font-size: 12px;
  font-weight: 800;
}

.services-section .owl-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
}

.services-section .owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #D9D9D9;
}

.services-section .owl-theme .owl-dots .owl-dot.active span {
  background: var(--theme-color);
  width: 6px;
  height: 6px;
  position: relative;
}

.services-section .owl-theme .owl-dots .owl-dot.active span:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #5D666F;
}

.services-section .shape-1 {
  position: absolute;
  bottom: -115px;
  left: 0;
}

.services-section .shape-2 {
  position: absolute;
  top: 0;
  left: 70px;
}

.services-section .icon-box-bottom {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 112px;
  color: #5D666F1A;
}

.services-section.style-2 .shape-plane {
  position: absolute;
  top: 0;
  left: 0;
}

.services-section.style-2 .shape-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 320px;
  background-repeat: repeat-x;
  animation: slide 60s linear infinite;
}

.services-section.style-2 .services-box {
  background: var(--white);
}


.services-section.style-2 .owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--color-1);
}

.services-section.style-2 .owl-theme .owl-dots .owl-dot.active span {
  background: var(--theme-color);
  width: 10px;
  height: 10px;
  position: relative;
}

.services-section.style-2 .owl-theme .owl-dots .owl-dot.active span:before {
  display: none;
}

.services-box.two {
  grid-template-columns: auto 285px;
}

@media only screen and (max-width: 1499px) {

  .services-section.style-2 .container {
    margin: 0 0 0 auto;
  }

}

@media only screen and (max-width: 1399px) {

  .services-box {
    grid-template-columns: 220px auto;
    gap: 30px;
  }

  .services-box.two {
    grid-template-columns: auto 220px;
  }

  .services-box .icon-box-top {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .services-box .content h4 {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 34px;
  }

  .services-section .icon-box-bottom {
    font-size: 80px;
  }

}

@media only screen and (max-width: 1199px) {

  .services-section.style-2 .container {
    margin: 0 auto;
  }

}

@media only screen and (max-width: 1024px) {

  .services-section .shape-2 {
    display: none;
  }

}

@media only screen and (max-width: 499px) {

  .services-box {
    grid-template-columns: au;
    gap: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 20px 30px 20px;
  }

}


/*----------------------------------------*/
/*   TESTIMONIALS SECTION CSS START
/*----------------------------------------*/

.testimonials-box {
  box-shadow: 0px 20px 60px 0px #0000000d;
  background: var(--white);
  padding: 45px 40px 40px;
  border-radius: 10px;
  border: 1px solid #1C263A26;
  cursor: pointer;
}

.testimonials-box .image-box {
  display: grid;
  grid-template-columns: 120px auto;
  position: relative;
}

.testimonials-box .content h5 {
  font-size: 24px;
  margin-bottom: 5px;
}

.testimonials-box .content span {
  font-size: 14px;
}

.testimonials-box .content ul {
  display: flex;
  gap: 5px;
  font-size: 36px;
  color: var(--theme-color);
  margin-top: 10px;
  margin-bottom: 25px;
}

.testimonials-box .image-box img {
  width: 100px;
  height: 100px;
  border: 5px solid var(--white);
  border-radius: 50%;
  position: relative;
}

.testimonials-box .image-holder:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 6px;
  width: 100px;
  height: 100px;
  background: var(--theme-color);
  border-radius: 50%;
}

.testimonial-1-active {
  padding: 18px;
  border-radius: 10px;
}

.swiper-slide:nth-child(odd) .testimonials-box .image-holder:before {
  background: #5D666F;
}

.testimonials-slider-navigation button {
  width: 50px;
  height: 50px;
  background: var(--white);
  box-shadow: 0px 4px 10px 0px #0000001A;
  border-radius: 50%;
  color: var(--theme-color);
  margin-right: 20px;
  transition: all 700ms ease;
}

.testimonials-slider-navigation button:hover {
  background: var(--theme-color);
  color: var(--white);
}

@media only screen and (max-width: 1780px) {

  .testimonials-slide-area {
    padding-left: 0px;
  }

}

@media only screen and (max-width: 1660px) {

  .testimonials-box {
    padding: 20px;
  }

  .testimonials-box .content ul {
    font-size: 20px;
    margin-bottom: 18px;
  }

}

@media only screen and (max-width: 1399px) {

  .testimonials-section .image {
    padding-right: 0;
  }

}

@media only screen and (max-width: 1299px) {

  .testimonials-box .image-box img {
    width: 75px;
    height: 75px;
  }

  .testimonials-box .image-holder:before {
    width: 75px;
    height: 75px;
  }

  .testimonials-box .image-box {
    grid-template-columns: 95px auto;
  }

  .testimonials-box .content h5 {
    font-size: 20px;
    margin-bottom: 0;
  }

  .testimonials-box .content ul {
    margin-top: 0;
  }

}

@media only screen and (max-width: 991px) {

  .testimonials-section figure.image {
    padding: 0;
    margin-bottom: 30px;
  }

  .testimonials-slide-area {
    padding-left: 0px;
    padding: 0 15px;
  }

  .testimonials-slider-navigation {
    display: none;
  }

}

/*----------------------------------------*/
/*   BRAND SECTION CSS START
/*----------------------------------------*/

.brand-active .image {
  filter: grayscale(1);
  opacity: 0.5;
  transition: all 500ms ease;
}

.brand-active .image:hover {
  filter: grayscale(0);
  opacity: 1;
}

/*----------------------------------------*/
/*   COACHING SECTION CSS START
/*----------------------------------------*/

.coaching-section {
  padding: 100px 0 160px 0;
}

.coaching-section .bg-shape {
  background: var(--ofwhite);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: auto;
}

.coaching-box-area {
  position: relative;
  padding: 40px;
  box-shadow: 0px 20px 60px 0px #7373731A;
  border-radius: 5px;
  border-bottom: 5px solid var(--theme-color);
  transition: all 700ms ease;
  background: var(--white);
  height: 90vh;
}

.coaching-box-area:before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 0;
  background: var(--color-1);
  border-radius: 0 0 5px 5px;
  transition: all 700ms ease;
}

.coaching-box-area:hover:before {
  height: 10px;
}

.coaching-box-area h4 {
  margin-top: 30px;
  margin-bottom: 20px;
}

.coaching-box-area p {
  margin-bottom: 20px;
}

.coaching-box-area .coaching-btn {
  font-size: 30px;
  transform: rotate(45deg);
  display: block;
  color: var(--color-1);
  transition: all 700ms ease;
}

.coaching-box-area:hover .coaching-btn {
  transform: rotate(0);
  color: var(--theme-color);
}

.coaching-box-area .image img {
  clip-path: polygon(0 0, 100% 0, 100% 67%, 50% 100%, 0 67%);
}

.coaching-box-area .image:before {
  content: "";
  height: 246px;
  width: 100%;
  position: absolute;
  top: 1px;
  left: 0;
  background: rgba(225, 56, 51, 0.4);
  border-radius: 5px;
  clip-path: polygon(0 0, 100% 0, 100% 66%, 50% 100%, 0 66%);
  transition: all 700ms ease
}

.coaching-box-area:hover .image:before {
  background: var(--theme-color);
}

.coaching-slider-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 60%;
  transform: translateY(-60%);
  left: 255px;
  right: 255px;
}

.coaching-slider-navigation button {
  width: 50px;
  height: 50px;
  background: var(--white);
  box-shadow: 0px 4px 10px 0px #0000001A;
  border-radius: 50%;
  color: var(--theme-color);
  transition: all 700ms ease;
}

.coaching-slider-navigation button:hover {
  background: var(--theme-color);
  color: var(--white);
}

@media only screen and (max-width: 1870px) {

  .coaching-slider-navigation {
    left: 200px;
    right: 200px;
  }

}

@media only screen and (max-width: 1770px) {

  .coaching-slider-navigation {
    left: 100px;
    right: 100px;
  }

}

@media only screen and (max-width: 1570px) {

  .coaching-slider-navigation {
    left: 50px;
    right: 50px;
  }

}

@media only screen and (max-width: 1470px) {

  .coaching-slider-navigation {
    left: 10px;
    right: 10px;
  }

}

@media only screen and (max-width: 1399px) {

  .coaching-box-area .image:before {
    height: 190px;
  }

}

@media only screen and (max-width: 1199px) {

  .coaching-slider-navigation {
    display: none;
  }

  .coaching-section {
    padding: 60px 0 100px 0;
  }

}

/*----------------------------------------*/
/*   BENEFITS SECTION CSS START
/*----------------------------------------*/
.benefits-left-area {
  cursor: pointer;
}

.benefits-left-area .icon-box {
  display: grid;
  grid-template-columns: 80px auto;
  gap: 20px;
  align-items: center;
  padding: 25px 30px;
}

.benefits-left-area .icon-area {
  font-size: 35px;
  width: 74px;
  height: 74px;
  padding: 20px;
  background: rgba(246, 246, 246, 1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--theme-color);
  position: relative;
}

.benefits-left-area .icon-box .icon-area:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0);
  background: var(--theme-color);
  border-radius: 50%;
  transition: all 700ms ease;
}

.benefits-left-area .icon-box:hover .icon-area:before {
  transform: scale(1);
}

.benefits-left-area .icon-box:hover .icon-area i {
  position: relative;
  color: var(--white);
}

.benefits-left-area .icon-box h5 {
  font-size: 24px;
  margin-bottom: 10px;
}

.benefits-left-area .icon-box p {
  font-family: var(--rubik-font);
}

.benefits-left-area .icon-box:last-child {
  border-top: 0;
}

.benefits-right-area .float-icon-area {
  max-width: 276px;
  box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.1);
  background: var(--white);
  padding: 35px 30px;
  position: absolute;
  bottom: 30px;
  left: -56px;
  border-radius: 5px;
}

.benefits-right-area .icon-box {
  display: flex;
  justify-content: start;
  align-items: end;
  gap: 20px;
  margin-bottom: 20px;
}

.benefits-right-area .icon-box:last-child {
  margin-bottom: 0;
}

.benefits-right-area .icon-box i {
  font-size: 60px;
  color: var(--theme-color);
}

.benefits-right-area .icon-box h3 {
  font-size: 36px;
  margin-bottom: 5px;
}

.benefits-right-area .icon-box h6 {
  font-family: var(--rubik-font);
  font-size: 16px;
  color: var(--gray);
  font-weight: 400;
}

.benefits-section {
  background: rgb(243, 243, 243, 100%);
}

.benefits-section .shape-1 {
  position: absolute;
  bottom: -35px;
  right: 0;
  animation: shake 30s linear infinite;
}

.benefits-section .shape-2 {
  position: absolute;
  top: 0;
  left: 0;
  animation: card-bounce 10s linear infinite;
}

.benefits-right-area {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media only screen and (max-width: 1770px) {

  .benefits-section .shape-2 {
    width: 150px;
  }

  .benefits-section .shape-1 {
    width: 270px;
  }

}

@media only screen and (max-width: 1399px) {

  .benefits-left-area .icon-box {
    grid-template-columns: 80px auto;
    gap: 15px;
    padding: 20px;
  }

  .benefits-section .shape-1 {
    bottom: 0;
  }

}

@media only screen and (max-width: 991px) {

  .benefits-section .shape-1,
  .benefits-section .shape-2,
  .benefits-right-area .float-icon-area {
    display: none;
  }

  .benefits-left-area {
    padding-right: 0;
  }

}

@media only screen and (max-width: 425px) {

  .benefits-left-area .icon-box {
    gap: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
  }

}

/*----------------------------------------*/
/*   TEAM SECTION CSS START
/*----------------------------------------*/

.team-section .shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.team-box-area figure:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background: rgba(197, 52, 47, 0.8);
  transition: all 700ms ease;
  cursor: pointer;
}

.team-box-area:hover figure:before {
  left: 0;
  width: 100%;
}

.team-box-area .content {
  position: absolute;
  bottom: 40px;
  left: 50px;
  right: 50px;
  text-align: center;
  opacity: 0;
  transition: all 900ms ease;
}

.team-box-area:hover .content {
  opacity: 1;
}

.team-box-area .content ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--white);
}

.team-box-area .content ul li {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 700ms ease;
}

.team-box-area .content ul li:hover {
  background: var(--theme-color);
}

.team-box-area .content h3 {
  color: var(--white);
  font-size: 36px;
  margin-top: 15px;
}

.team-box-area .content h3:hover {
  color: var(--theme-color);
}

.team-box-area .content span {
  color: var(--white);
}

@media only screen and (max-width: 1440px) {

  .team-box-area .content {
    left: 55px;
    right: 55px;
  }

  .team-box-area .content h3 {
    font-size: 24px;
    margin-top: 5px;
  }

}

/*----------------------------------------*/
/*   FAQ SECTION CSS START
/*----------------------------------------*/

.faq-box-area * {
  color: var(--white);
}

.faq-box-area .section-sub-title span {
  color: var(--theme-color);
}

.faq-box-area .accordion-button {
  text-align: left;
  color: var(--white);
  background: transparent;
  gap: 15px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
}

.faq-box-area .accordion-button::after {
  content: "\2b";
  background-image: none;
  font-family: 'Font Awesome 5 Pro';
  font-size: 15px;
  font-weight: 400;
  height: 20px;
  width: 20px;
  border: none;
  line-height: 21px;
  border-radius: 50%;
  margin-inline-start: 0;
  text-align: center;
  position: absolute;
  right: 0px;
  background: var(--theme-color);
  color: #fff;
}

.faq-box-area .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
  color: var(--white);
  border: none;
}

.faq-box-area .accordion-button:not(.collapsed)::after {
  content: "\f068";
  font-family: "Font Awesome 6 Pro";
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: var(--black);
  width: 20px;
  height: 20px;
  background: var(--theme-color);
}

.faq-box-area .accordion-button:focus {
  box-shadow: none;
}

.faq-box-area .accordion-body {
  font-size: 14px;
  line-height: 15px;
  color: var(--white);
}

.faq-box-area .accordion-collapse {
  border: none;
  transition: all 0.5s;
}

.faq-box-area .accordion-item {
  border: none;
  border-radius: 0;
  background: transparent;
  border: 1px solid #FFFFFF33;
  padding: 0px 5px;
}

.faq-box-area .accordion-item:first-of-type .accordion-button {
  border-radius: 0px;

}

.faq-box-area .accordion-item:last-of-type .accordion-button.collapsed {
  border-width: 1px;
  border-radius: 0px;
}

.faq-box-area .vw-faq-group {
  display: flex;
  flex-direction: column;
  gap: 11px;
}

/* booking area */

.booking-form--wrapper .common .nice-select::after {
  position: absolute;
  content: '\f107';
  font-family: 'Font Awesome 5 Pro';
  font-size: 16px;
  top: 7px;
  right: 25px;
}

/* Flatpickr */
.flatpickr-time {
  font-family: var(--rubik-font);
  font-weight: 400;
  border-radius: 0px;
  padding: 5px 10px;
  height: 100%;
  border-width: 0px;
}

.booking-form--wrapper h2 {
  font-size: 48px;
  line-height: 58px;
  margin-bottom: 35px;
  color: rgb(216, 6, 33);
}

.booking-form--wrapper .flatpickr-time {
  text-align: left;
}

.booking-form--wrapper {
  padding: 80px 94px 80px 94px;
  border-radius: 2px;
  background-repeat: no-repeat;
  background-position: left bottom;
  position: relative;
}

.booking-form--inner .form-group .form-control {
  background: transparent;
  color: rgb(216, 27, 61, 100%);
  border-bottom: 1px solid rgb(216, 27, 61, 100%);
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
}

.booking-form--inner .form-group .form-control {
  color: rgb(216, 6, 33);
}

.booking-form--inner input[type=text],
.booking-form--inner input[type=tel] {
  color: rgb(216, 6, 33);
}

.booking-form--inner ::placeholder {
  color: rgb(216, 6, 33);
}

.booking-form--wrapper .common .nice-select {
  background: transparent;
  color: rgb(216, 6, 33);
  border-bottom: 1px solid rgb(216, 6, 33);
  border-radius: 0;
  line-height: 42px;
}

.booking-form--wrapper .common .nice-select ul li {
  color: var(--color-1);
}

.booking-form--wrapper .common .nice-select:focus {
  border: none;
  background: transparent;
}

.booking-form--wrapper .form-control:focus {
  border: none;
  outline: 0;
  box-shadow: none;
  background: transparent;
}

.booking--button .btn {
  font-size: 14px;
  padding: 10px 20px;
  background: rgb(216, 6, 33);
  color: #fff;
  border: none;
  transition: all 700ms ease;
}

.booking--button .btn:hover {}

.booking-form--wrapper .form-group.book_date:after {
  content: "\e91c";
  font-family: 'icomoon';
  position: absolute;
  top: 5px;
  right: 25px;
  color: rgb(216, 6, 33);
}

.booking-form--wrapper .form-group.book_time:before {
  content: "\e91d";
  font-family: 'icomoon';
  position: absolute;
  top: 17px;
  right: 25px;
  color: rgb(216, 6, 33);
}

/* .flatpickr-months .flatpickr-month {
  overflow: visible
} */

.booking-form--wrapper .flatpickr-current-month ul {
  display: none;
}

.booking-form--wrapper .flatpickr-current-month .flatpickr-monthDropdown-months {
  margin-top: 2px;
}

.booking-area {
  padding-left: 100px;
  padding-bottom: 80px;
}

.booking-area p {
  color: var(--white);
  margin-bottom: 25px;
}

.faq-section .shape-1 {
  position: absolute;
  top: 10px;
  left: 0;
  animation: wobble-vertical 7s linear infinite;
}

.faq-section .shape-2 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.blog-section-2 .blog-style-1 {
  display: flex;
  gap: 27px;
}

.blog-section-2 .blog-style-1 .date-excerpt {
  top: inherit;
  bottom: 0;
  left: -210px;
}

@media only screen and (max-width: 1870px) {

  .faq-section .shape-1,
  .faq-section .shape-2 {
    width: 150px;
  }

}

@media only screen and (max-width: 1570px) {

  .faq-section .shape-1 {
    width: 100px;
  }

  .faq-section .container {
    max-width: 1200px;
  }

  .booking-area {
    padding-left: 50px;
    padding-bottom: 80px;
  }

  .booking-form--wrapper {
    padding: 80px 70px 80px 70px;
  }

}

@media only screen and (max-width: 1399px) {

  .booking-area {
    padding-left: 20px;
    padding-bottom: 80px;
  }

  .booking-form--wrapper {
    padding: 60px 65px 80px 65px;
  }

  .faq-box-area .accordion-item {
    padding: 0px 15px;
  }

  .faq-section .container {
    max-width: 1000px;
  }

}


@media only screen and (max-width: 1299px) {

  .faq-section .shape-2 {
    width: 200px;
  }

  .faq-box-area .accordion-button {
    font-size: 18px;
  }

  .faq-box-area .accordion-body {
    font-size: 12px;
    line-height: 15px;
    color: var(--white);
  }

}

@media only screen and (max-width: 1199px) {

  .faq-section .shape-1,
  .faq-section .shape-2 {
    display: none;
  }

}

@media only screen and (max-width: 991px) {

  .booking-area {
    padding-left: 0px;
    margin-top: 60px;
    margin-bottom: 80px;
  }

  .booking-form--wrapper {
    padding: 50px;
    padding-bottom: 80px;
  }

  .booking-form--wrapper h2 {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 25px;
  }

  .faq-section .shape-1,
  .faq-section .shape-2 {
    display: none;
  }

}

@media only screen and (max-width: 425px) {

  .booking-form--wrapper {
    padding: 30px;
    padding-bottom: 70px;
  }

  .booking-form--wrapper h2 {
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 25px;
  }

}

/*----------------------------------------*/
/*   BLOG SECTION CSS START
/*----------------------------------------*/


/* hover effects */

.blog-style-1 .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  mix-blend-mode: darken;
}

.blog-style-1 .image img {
  position: relative;
  width: 100%;
  display: block;
  -webkit-transition: all 700ms ease;
  transition: all 700ms ease;
}

.blog-style-1 .image img:first-child {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  transform: scale(0.5);
  opacity: 0;
  -webkit-filter: blur(10px);
  filter: blur(10px);
}

.blog-style-1:hover .image {
  position: relative;
  overflow: hidden;
}

.blog-style-1:hover .image img:first-child {
  transform: scale(1);
  opacity: 1;
  -webkit-filter: blur(0);
  filter: blur(0);
}

/* hover effects end*/

.blog-style-1 {
  box-shadow: 0px 4px 20px 0px #D2CACA40;
  padding: 20px;
  border-radius: 10px;
}

.blog-style-1 .image img {
  border-radius: 5px;
}

.blog-style-1 .content {
  margin-top: 16px;
}

.blog-style-1 .date-excerpt {
  position: absolute;
  top: -62px;
  left: 60px;
  padding: 10px 40px;
  background: var(--black);
  color: var(--white);
  clip-path: polygon(6% 0, 94% 0, 100% 100%, 0% 100%);
  transition: all 700ms ease;
}

.blog-style-1 .info-excerpt {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 15px;
}

.blog-style-1 h4 {
  text-decoration: underline;
  font-size: 24px;
  text-transform: capitalize;
}

.blog-style-1 h4:hover,
.blog-style-1:hover .blog-btn {
  color: var(--theme-color);
}

.blog-style-1:hover .date-excerpt {
  background: var(--theme-color);
}

.blog-section .shape {
  position: absolute;
  top: -255px;
  left: 0;
}

@media only screen and (max-width: 1550px) {

  .blog-section.style-2 .container {
    margin: 0 0 0 auto;
  }

}

@media only screen and (max-width: 991px) {

  .blog-section .shape {
    display: none;
  }

  .blog-section.style-2 .container {
    margin: 0 auto;
  }

}

/*----------------------------------------*/
/*   BANNER 3 SECTION CSS START
/*----------------------------------------*/

.banner-section-3 .container-fluid {
  padding: 0 120px;
}

.banner-3-slider-area:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(80.79deg, #E13833 5.7%, rgba(15, 15, 15, 0) 86.48%);
  border-radius: 10px;
}

.banner-3-container {
  padding: 50px 15px 140px 0;
}

.banner-3-container .right-box {
  max-width: 1528px;
  margin: 0 0 0 auto;
}

.banner-3-container .right-box .banner-3-slider-area {
  padding: 355px 100px 200px 100px;
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-3-container .banner-3-slider-area h2 {
  font-size: 100px;
  line-height: 110px;
  color: var(--white);
  position: relative;
}

.banner-3-container .banner-3-slider-area span {
  color: var(--white);
  position: relative;
  font-size: 14px;
  letter-spacing: 10px;
  text-transform: uppercase;
}

.banner-section-3 .shape-1 {
  position: absolute;
  top: 0;
  right: 0;
}

.banner-section-3 .shape-2 {
  position: absolute;
  top: 60px;
  left: 20px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top left;
}

.banner-3-container .icon-box {
  width: 154px;
  height: 154px;
  background: var(--theme-color);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 15px solid var(--white);
  border-radius: 50%;
  font-size: 40px;
  position: absolute;
  top: -30px;
  right: -30px;
  opacity: 0;
  transition: all 1300ms ease;
}

.banner-3-content .text-1 {
  position: absolute;
  bottom: -591px;
  left: -347px;
  transform: rotate(-90deg) scale(1.2);
  font-size: 160px;
  opacity: 0;
  transition: all 1300ms ease;
}

.banner-3-content .text-1 span {
  color: var(--theme-color);
}

.banner-3-content .text-2 {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #D4D4D4;
  color: transparent;
  position: absolute;
  bottom: -596px;
  left: -383px;
  transform: rotate(-90deg);
  font-size: 180px;
}

.swiper-slide-active .banner-3-content .text-1 {
  opacity: 1;
  transform: rotate(-90deg) scale(1);
  transition-delay: 1500ms;
}

.swiper-slide-active .banner-3-container .icon-box {
  opacity: 1;
  transform: rotate(-360deg) scale(1);
}

.banner-3-dot .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: var(--gray) !important;
  opacity: 1;
  margin-right: 10px;
}

.banner-3-dot .swiper-pagination-bullet-active {
  background: var(--theme-color) !important;
  position: relative;
  width: 10px;
  height: 10px;
  margin-top: 2px;
}

.banner-3-dot .swiper-pagination-bullet-active:before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  width: 14px;
  height: 14px;
  border: 1px solid var(--gray);
  border-radius: 50%
}

.banner-3-dot .banner-custom-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  gap: 8px;
  bottom: 150px;
  left: -15px;
  flex-direction: column;
  width: 135px;
}

@media only screen and (max-width: 1899px) {

  .banner-3-container .right-box {
    max-width: 1400px;
  }

}

@media only screen and (max-width: 1766px) {

  .banner-3-container .right-box {
    max-width: 1300px;
  }

}

@media only screen and (max-width: 1660px) {

  .banner-3-container .right-box {
    max-width: 1200px;
  }

}

@media only screen and (max-width: 1570px) {

  .banner-3-container .right-box {
    max-width: 1100px;
  }

}

@media only screen and (max-width: 1456px) {

  .banner-3-container .right-box {
    max-width: 1000px;
  }

  .banner-3-container .right-box .banner-3-slider-area {
    padding: 255px 50px 200px 50px;
  }

  .banner-3-container .banner-3-slider-area h2 {
    font-size: 68px;
    line-height: 78px;
  }

  .banner-3-content .text-1 {
    bottom: -500px;
    left: -245px;
    font-size: 110px;
  }

  .banner-3-content .text-2 {
    bottom: -490px;
    left: -283px;
    font-size: 130px;
  }

}

@media only screen and (max-width: 1335px) {

  .banner-3-container .right-box {
    max-width: 900px;
  }

}

@media only screen and (max-width: 1235px) {

  .banner-3-container .right-box {
    max-width: 850px;
  }

}

@media only screen and (max-width: 1199px) {

  .bar_line,
  .banner-3-dot .banner-custom-pagination,
  .banner-3-content .text-1,
  .banner-3-content .text-2 {
    display: none;
  }

}

@media only screen and (max-width: 991px) {

  .banner-3-container .banner-3-slider-area h2 {
    font-size: 48px;
    line-height: 58px;
  }

  .banner-3-container .right-box .banner-3-slider-area {
    padding: 155px 15px 100px 15px;
  }

  .banner-section-3 .shape-2,
  .bar_line {
    display: none;
  }

  .banner-3-container {
    padding: 50px 0 50px 0;
  }

  .banner-section-3 .container-fluid {
    padding: 0 15px;
  }

  .banner-3-container .right-box {
    margin: 0 auto;
  }

}

@media only screen and (max-width: 767px) {

  .banner-section-3 .icon-box {
    display: none;
  }

  .banner-3-container .banner-3-slider-area span {
    letter-spacing: 4px;
  }

  .banner-3-container .banner-3-slider-area h2 {
    font-size: 36px;
    line-height: 46px;
  }

  .banner-3-container .right-box .banner-3-slider-area {
    padding: 100px 15px 75px 15px;
  }

}

/*----------------------------------------*/
/*   VISA 3 SECTION CSS START
/*----------------------------------------*/

.visa-3-cat-section .container-fluid {
  padding: 100px 134px 125px 134px;
}

.visa-3-middle-area {
  padding-left: 50px;
  padding-right: 48px;
}

.visa-3-middle-area .text-1 {
  font-family: 'Cormorant';
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  text-transform: capitalize;
}

.visa-3-right-area .wptb-radial-progress {
  bottom: 56px;
  left: -75px;
}

.visa-3-left-area .icon-box-area .icon-box {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row-reverse;
  gap: 35px;
}

.visa-3-left-area .icon-box-area .icon-box i {
  font-size: 45px;
  color: var(--theme-color);
  transition: all 700ms ease;
}

.visa-3-left-area .icon-box-area p {
  font-family: var(--rubik-font);
}

.visa-3-left-area .content-area {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 30px;
  width: 640px;
}

.visa-3-left-area .icon-box-area .icon-box:hover i {
  color: var(--color-1);
}

.visa-3-left-area .icon-box-area .icon-box:hover h4 {
  color: var(--theme-color);
}

.visa-3-left-area .icon-box-area .icon-box:hover i {
  color: var(--color-1);
}

.visa-3-left-area .icon-box-area .icon-box:hover h4 {
  color: var(--theme-color);
}

.visa-3-cat-section .shape-1 {
  position: absolute;
  top: 75px;
  right: 40px;
  animation: shake 20s linear infinite;
}

.visa-3-cat-section .shape-2 {
  position: absolute;
  top: 127%;
  right: 25%;
  animation: wobble-vertical 5s linear infinite;
}

.visa-3-left-area .image img,
.visa-3-right-area .image img {
  border-radius: 10px;
}

/* hover effects start */

.visa-3-left-area figure.image {
  width: 100%;
  z-index: 1;
  position: relative;
  display: block;
  overflow: hidden;
}

.visa-3-left-area figure.image:before {
  position: absolute;
  top: 0;
  left: -75%;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, .3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.visa-3-left-area:hover figure.image:before {
  animation: shine 1s;
}

/* hover effects end */

.visa-3-right-area .image {
  overflow: hidden;
  transition: all 700ms ease;
  border-radius: 10px;
}

.visa-3-right-area:hover .image img {
  transform: scale(1.2);
}


@media only screen and (max-width: 1870px) {

  .visa-3-right-area .wptb-radial-progress {
    bottom: 18px;
    left: -75px;
  }

}

@media only screen and (max-width: 1675px) {

  .visa-3-middle-area {
    padding-left: 0;
    padding-right: 25px;
  }

  .visa-3-right-area .wptb-radial-progress {
    bottom: 18px;
    left: -30px;
  }

  .visa-3-middle-area .text-1 {
    font-size: 20px;
    margin: 0 0 20px;
  }

  .visa-3-middle-area .text-2 {
    margin: 0 0 20px;
  }

  .visa-3-left-area .icon-box-area .icon-box h4 {
    font-size: 22px;
    line-height: 32px;
  }

  .visa-3-left-area .icon-box-area .icon-box {
    gap: 15px;
  }

  .visa-3-cat-section .shape-2 {
    top: 110%;
    right: -40px;
  }

}

@media only screen and (max-width: 1499px) {

  .visa-3-left-area .content-area {
    width: auto;
  }

  .visa-3-cat-section .container-fluid {
    padding: 100px 50px 125px 134px;
  }

}

@media only screen and (max-width: 1299px) {

  .visa-3-cat-section .shape-2 {
    top: 95%;
    right: -40px;
  }

  .visa-3-left-area .content-area {
    gap: 20px;
    flex-direction: column;
  }

}

@media only screen and (max-width: 1099px) {

  .visa-3-cat-section .shape-1,
  .visa-3-cat-section .shape-2 {
    display: none;
  }

}

@media only screen and (max-width: 991px) {

  .visa-3-left-area .content-area {
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .visa-3-cat-section .container-fluid {
    padding: 60px 15px 60px 15px;
  }

  .visa-3-right-area {
    margin-top: 30px;
  }

  .visa-3-middle-area,
  .visa-3-left-area .image {
    padding-right: 0;
  }

}


/*----------------------------------------*/
/*   COUNTRY LIST 3 SECTION CSS START
/*----------------------------------------*/

.title-box-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.country-1-active .bg-map {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.country-3-list-box {
  height: 495px;
  border-right: 1px solid #1C263A26;
  transition: all 700ms ease;
}

.country-3-list-box .inner {
  position: absolute;
  bottom: 60px;
  left: 50px;
  right: 50px;
}

.country-1-active {
  border: 1px solid #0000001A;
  border-right: 0;
  border-radius: 10px;
  background: var(--white);
}

.country-3-list-box img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: -30px;
  transition: all 700ms ease;
}

.country-3-list-box:hover img {
  margin-bottom: 0px;
}

.country-3-list-box h2 {
  padding: 5px 0;
  font-size: 48px;
  line-height: 58px;
  color: var(--theme-color);
  opacity: 0;
  transform: scale(0);
  transition: all 700ms ease;
}

.country-3-list-box:hover h2 {
  opacity: 1;
  transform: scale(1);
}

.country-3-list-box h4 {
  font-size: 24px;
  line-height: 34px;
  text-transform: capitalize;
}

.country-3-list-box ul {
  margin-left: 30px;
}

.country-3-list-box ul li {
  position: relative;
  margin-bottom: 8px;
}

.country-3-list-box ul li:before {
  content: "\e931";
  font-family: 'icomoon';
  font-size: 22px;
  position: absolute;
  top: 0;
  left: -30px;
}

.country-3-list-box .bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: all 700ms ease;
}

.country-3-list-box:hover .bg-img {
  opacity: 1;
}

.custom-container {
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 15px;
}

.country-3-list-section .shape-1 {
  position: absolute;
  top: 0;
  left: 0;
}

.country-3-list-section .shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  animation: shake 20s linear infinite;
}

.country-3-list-section .shape-3 {
  position: absolute;
  top: 0;
  right: 0;
}

.country-3-list-section .float-text h2 {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #D4D4D4;
  color: transparent;
  font-size: 100px;
  line-height: 110px;
  position: absolute;
  top: 0;
  letter-spacing: 8px;
}

.country-1-active .owl-nav.disabled {
  display: none;
}

@media only screen and (max-width: 1820px) {

  .country-3-list-section .shape-2 {
    width: 200px;
  }

}

@media only screen and (max-width: 1560px) {

  .custom-container {
    margin: 0 50px 0 auto;
  }

}

@media only screen and (max-width: 1499px) {

  .custom-container {
    max-width: 1220px;
    margin: 0 50px 0 auto;
  }

}

@media only screen and (max-width: 1370px) {

  .custom-container {
    max-width: 1050px;
    margin: 0 20px 0 auto;
  }

  .country-3-list-box .inner {
    bottom: 40px;
    left: 30px;
    right: 30px;
  }

  .country-3-list-box h4 {
    font-size: 21px;
    line-height: 31px;
  }

  .country-3-list-box {
    height: 395px;
  }

}

@media only screen and (max-width: 991px) {

  .custom-container {
    margin: 0 auto;
  }

  .title-box-area {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }

  .title-box-area p {
    margin-top: 30px;
  }

  .country-3-list-section .float-text h1,
  .country-3-list-section .shape-2 {
    display: none;
  }

}

/*----------------------------------------*/
/*   CTA 3 SECTION CSS START
/*----------------------------------------*/

.cta-3-main {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 180px 0;
}

.cta-3-section .container-fluid {
  padding: 0;
  margin-left: 135px;
}

.cta-3-main .container {
  display: grid;
  grid-template-columns: 330px auto;
  align-items: center;
  gap: 100px;
}

.cta-3-main .container h2 {
  font-size: 100px;
  line-height: 110px;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.cta-3-main .left-area .icon-box {
  width: 328px;
  height: 328px;
  background: var(--theme-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
}

.cta-3-main .left-area .icon-box:before {
  content: "";
  position: absolute;
  top: 17px;
  left: 17px;
  width: 290px;
  height: 290px;
  border: 1px dashed #fff;
  border-radius: 50%;
  animation: spin 40s linear infinite;
}

.cta-3-section .shape-1 {
  position: absolute;
  top: 0;
  left: 0;

}

.cta-3-section.style-3 .container-fluid {
  padding: 0;
  margin-left: 0px;
}

@media only screen and (max-width: 1599px) {

  .cta-3-main {
    padding: 100px 0;
  }

  .cta-3-main .container {
    gap: 65px;
  }

  .cta-3-main .container h1 {
    font-size: 70px;
    line-height: 80px;
  }

}

@media only screen and (max-width: 1199px) {

  .cta-3-section .container-fluid {
    margin-left: 0;
  }

  .cta-3-main .container {
    display: flex;
    align-items: center;
    gap: 35px;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
  }

  .cta-3-main .left-area .icon-box {
    width: 250px;
    height: 250px;
  }

  .cta-3-main .left-area .icon-box:before {
    top: 12px;
    left: 12px;
    width: 225px;
    height: 225px;
  }

  .cta-3-main .container h1 {
    font-size: 42px;
    line-height: 52px;
    margin-bottom: 20px;
  }

}

/*----------------------------------------*/
/*   WORK PROCESS SECTION CSS START
/*----------------------------------------*/

.work-3-process-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.work-3-process-box {
  max-width: 260px;
}

.work-3-process-box img {
  width: 214px;
  height: 214px;
  border-radius: 50%;
}

.work-3-process-box .image-box:before {
  content: "";
  position: absolute;
  top: -19px;
  left: 2px;
  width: 254px;
  height: 254px;
  border: 1px dashed var(--gray);
  border-radius: 50%;
  animation: spin 30s linear infinite;
}

.work-3-process-box .image-box h3 {
  width: 72px;
  height: 72px;
  background: var(--theme-color);
  border-radius: 50%;
  font-size: 36px;
  padding: 5px;
  color: var(--white);
  border: 3px solid var(--white);
  position: absolute;
  bottom: 0;
  right: 91px;
  left: 91px;
}

.work-3-process-box h5 {
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 34px;
}

.work-3-process-box:before {
  content: "";
  position: absolute;
  top: 0;
  right: -131px;
  width: 1px;
  height: 372px;
  background: #5D666F33
}

.work-3-process-box:after {
  content: "\e93f";
  font-family: 'icomoon';
  position: absolute;
  top: 99px;
  right: -143px;
  width: 26px;
  height: 26px;
  background: var(--gray-2);
  font-size: 10px;
  color: var(--white);
  animation: wobble-vertical 6s linear infinite;
}

.work-3-process-main .work-3-process-box:last-child:before,
.work-3-process-main .work-3-process-box:last-child:after {
  display: none;
}

.work-3-process-section .shape-1 {
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 1499px) {

  .work-3-process-section .container {
    max-width: 1100px;
  }

  .work-3-process-box:before {
    right: -67px;
  }

  .work-3-process-box:after {
    right: -81px;
  }

  .work-3-process-section .shape-1 {
    width: 300px;
  }

}


@media only screen and (max-width: 1299px) {

  .work-3-process-section .container {
    max-width: 1000px;
  }

  .work-3-process-box:before {
    right: -40px;
  }

  .work-3-process-box:after {
    right: -53px;
  }

}

@media only screen and (max-width: 991px) {

  .work-3-process-section .shape-1,
  .work-3-process-box:before,
  .work-3-process-box:after {
    display: none;
  }

  .work-3-process-main {
    flex-direction: column;
    gap: 50px;
  }

}

/*----------------------------------------*/
/*   CHOOSE SECTION CSS START
/*----------------------------------------*/


/* hover effects */

.choose-3-section .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  mix-blend-mode: darken;
}

.choose-3-section .image img {
  position: relative;
  width: 100%;
  display: block;
  -webkit-transition: all 700ms ease;
  transition: all 700ms ease;
}

.choose-3-section .image img:first-child {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  transform: scale(0.5);
  opacity: 0;
  -webkit-filter: blur(10px);
  filter: blur(10px);
}

.choose-3-section:hover .image {
  position: relative;
  overflow: hidden;
}

.choose-3-section:hover .image img:first-child {
  transform: scale(1);
  opacity: 1;
  -webkit-filter: blur(0);
  filter: blur(0);
}

/* hover effects end*/

.choose-3-box {
  border: 1px solid #0000001A;
  border-radius: 5px;
  background: var(--white);
  padding: 25px 35px 25px 70px;
  margin-bottom: 20px;
}

.choose-3-box h5 {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 19px;
}

.choose-3-box h5:hover {
  color: var(--theme-color);
}

.choose-3-box .icon-box {
  width: 78px;
  height: 78px;
  background: var(--black);
  color: var(--white);
  border-radius: 50%;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -39px;
  top: 50%;
  transform: translateY(-50%);
}

.choose-3-box .icon-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--theme-color);
  border-radius: 50%;
  transform: scale(0);
  transition: all 700ms ease;
}

.choose-3-box:hover .icon-box:before {
  transform: scale(1);
}

.choose-3-box:hover .icon-box i {
  position: relative;
}

.choose-3-section .shape-plane {
  position: absolute;
  top: 32px;
  right: 0;
}

.choose-3-section .shape-map {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top left;
}

@media only screen and (max-width: 1620px) {

  .choose-3-section .container {
    margin: 0 0 0 auto;
  }

}

@media only screen and (max-width: 1450px) {

  .choose-3-section .container {
    max-width: 1200px;
  }

}

@media only screen and (max-width: 1350px) {

  .choose-3-section .container {
    max-width: 1100px;
  }

}

@media only screen and (max-width: 1250px) {

  .choose-3-section .container {
    max-width: 1050px;
  }

}

@media only screen and (max-width: 1250px) {

  .choose-3-section .container {
    margin: 0 auto;
  }

}


@media only screen and (max-width: 1199px) {

  .choose-3-box .icon-box {
    display: none;
  }

  .choose-3-box {
    padding: 20px;
  }

}

/*----------------------------------------*/
/*   IMAGE 3 SECTION CSS START
/*----------------------------------------*/

.image-3-area i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 100px;
  color: var(--white);
  opacity: 0;
  transition: all 700ms ease;
}

.image-3-area {
  overflow: hidden;
}

.image-3-area:hover i {
  opacity: 1;
  transform: translate(-50%, -50%) rotate(90deg);
}

.image-3-area:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(360deg, rgba(226, 9, 53, 0.8) 32%, rgba(244, 52, 91, 0.8) 60.91%);
  transition: all 700ms ease;
}

.image-3-area:hover:before {
  left: 0;
  width: 100%;
}

/*----------------------------------------*/
/*   FAQ 3 SECTION CSS START
/*----------------------------------------*/

.style-2 .faq-box-area *,
.style-2 .faq-box-area .accordion-button:not(.collapsed),
.style-2 .booking-area p {
  color: var(--black);
}

.faq-section.style-2 .section-sub-title {
  color: var(--theme-color);
}

.style-2 .faq-box-area .accordion-item {
  border: 1px solid #00000033;
}

.style-2 .faq-box-area .accordion-button:not(.collapsed)::after,
.style-2 .faq-box-area .accordion-button::after {
  color: var(--white);
}

.style-2 .booking-area {
  padding-left: 0px;
  margin-bottom: 0;
}

.style-2 .vw-faq-group {
  padding-right: 100px;
}

.style-2 .faq-conter-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 150px;
}

.style-2 .faq-conter-box .inner {
  padding: 25px;
  border: 1px solid #1C263A1A;
  border-radius: 5px;
}

.style-2 .faq-conter-box .inner h2 {
  font-size: 48px;
  line-height: 58px;
  margin-bottom: 10px;
}

.style-2 .faq-conter-box .inner h2 .unit {
  color: var(--theme-color);
}

.style-2 .booking-form--wrapper {
  padding: 80px 90px 80px 90px;
  position: relative;
}

.faq-section.style-2:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 170px;
  background: var(--white);
}

.faq-section.style-2 .shape-2 {
  position: absolute;
  top: 0;
  right: 0;
}

.faq-section.style-2 .shape-3 {
  position: absolute;
  bottom: -25px;
  left: 0;
}

@media only screen and (max-width: 1799px) {

  .faq-section.style-2 .shape-3 {
    width: 135px;
  }

}

@media only screen and (max-width: 1399px) {

  .style-2 .booking-form--wrapper {
    padding: 60px 60px 70px 60px;
  }

  .style-2 .vw-faq-group {
    padding-right: 0;
  }

  .style-2 .faq-conter-box {
    margin-top: 100px;
  }

  .style-2 .faq-conter-box .inner {
    padding: 15px;
  }

  .style-2 .faq-conter-box .inner h2 {
    font-size: 36px;
    line-height: 46px;
  }

  .faq-section.style-2:before {
    height: 174px;
  }

  .booking-form--wrapper .common .nice-select:after {
    right: 0;
  }

}

@media only screen and (max-width: 1299px) {

  .style-2 .faq-conter-box {
    margin-top: 124px;
  }

  .faq-section.style-2:before {
    height: 140px;
  }

  .booking-form--wrapper h2 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 15px;
  }

}

@media only screen and (max-width: 1250px) {

  .faq-section.style-2 .container {
    max-width: 1100px;
  }

}

@media only screen and (max-width: 991px) {

  .style-2 .vw-faq-group {
    padding-right: 0px;
  }

  .style-2 .faq-conter-box {
    margin-top: 50px;
  }

}

@media only screen and (max-width: 575px) {

  .style-2 .faq-conter-box {
    flex-direction: column;
    gap: 20px;
  }

  .sidebar__toggle {
    display: inline;
  }

  .style-2 .booking-form--wrapper {
    padding: 30px;
    padding-bottom: 70px;
  }

}

/*----------------------------------------*/
/*   TRAINING SECTION CSS START
/*----------------------------------------*/

.training-3-main {
  display: grid;
  grid-template-columns: 770px auto;
  justify-content: space-between;
  border: 1px solid #1C263A26;
  padding: 20px;
  border-radius: 5px;
  gap: 30px;
  margin-bottom: 20px;
}

.training-3-main .left-box {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 40px;
}

.training-3-main .right-box {
  display: grid;
  align-items: center;
  gap: 75px;
  grid-template-columns: 210px auto;
}

.training-3-main .right-box ul li {
  position: relative;
  margin-left: 15px;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 10px;
}

.training-3-main .right-box ul li:last-child {
  margin-bottom: 0;
}

.training-3-main .right-box ul li:before {
  content: "\e931";
  font-family: 'icomoon';
  position: absolute;
  top: 0;
  left: -25px;
  color: var(--theme-color);
}

.training-3-main .training-price h2 {
  font-size: 48px;
  line-height: 58px;
}

.training-3-main .training-price span {
  font-size: 14px;
  display: block;
}

.training-3-main .training-content p {
  line-height: 29px;
}

@media only screen and (max-width: 1499px) {

  .training-3-section .container {
    margin: 0 0 0 auto;
  }

  .training-3-section.style-2 .container {
    margin: 0 auto;
  }

}

@media only screen and (max-width: 1399px) {

  .training-3-main {
    grid-template-columns: 620px auto;
  }

  .training-3-main .left-box {
    display: grid;
    grid-template-columns: 300px auto;
    gap: 25px;
  }

  .training-3-main .right-box {
    gap: 30px;
    grid-template-columns: auto 150px;
  }

}


@media only screen and (max-width: 1225px) {

  .training-3-section .container {
    max-width: 1100px;
  }

}

@media only screen and (max-width: 1199px) {

  .training-3-section .container {
    margin: 0 auto;
  }

  .training-3-main {
    display: flex;
    flex-direction: column;
  }

  .training-3-main .right-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .training-3-main .left-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

}

/*----------------------------------------*/
/*   TESTIMONIALS 3 SECTION CSS START
/*----------------------------------------*/

.testimonials-3-section.style-2 {
  margin-left: 315px;
}

.testimonials-3-left {
  padding-right: 150px;
}

.shape-2-globe {
  position: absolute;
  top: -100px;
  left: 50%;
  transform: translateX(-80%);
}

@media only screen and (max-width: 1799px) {

  .testimonials-3-section.style-2 {
    margin-left: 230px;
  }

  .testimonials-3-left {
    padding-right: 20px;
  }

}

@media only screen and (max-width: 1399px) {

  .testimonials-3-section.style-2 {
    margin-left: 130px;
  }

  .footer-widget-3 span {
    font-size: 14px;
  }

}

@media only screen and (max-width: 1199px) {

  .testimonials-3-section.style-2 {
    margin-left: 0;
  }

  .testimonial-1-active {
    padding: 0;
    margin-top: 30px;
  }

}

/*----------------------------------------*/
/*   HOME 4 CSS START
/*----------------------------------------*/

.header-top-4 .container-fluid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 312px;
}

.header-top-4 .socials-info-1 {
  padding: 13px 312px 13px 83px;
}

.header-top-4 .socials-info-1 ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
}

.header-top-4 .socials-info-1 ul li {
  font-size: 20px;
  color: var(--white);
}

.header-top-4 .header-info ul {
  gap: 36px;
}

.header-top-4 .header-info ul li {
  color: var(--white);
  ;
}

.header-four .main-menu ul li {
  margin-inline-end: 60px;
}

.header-four .header-left {
  gap: 65px;
}


@media only screen and (max-width: 1499px) {

  .header-top-4 .container-fluid {
    padding: 0 0 0 112px;
  }

  .header-top-4 .socials-info-1 {
    padding: 13px 112px 13px 83px;
  }

}

@media only screen and (max-width: 1399px) {

  .header-four .main-menu ul li {
    margin-inline-end: 40px;
  }

}

@media only screen and (max-width: 1100px) {

  .header-top-4 .container-fluid {
    display: none;
  }

}

@media only screen and (max-width: 991px) {

  .header-four .header-box-main {
    padding: 20px;
  }

}

/*----------------------------------------*/
/*   BANNER 4 CSS START
/*----------------------------------------*/

.banner-4-slider {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.banner-4-slider:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #D81B3D 100%);
}

.banner-4-slider .content {
  padding: 365px 0 240px 0;
}

.banner-4-slider .content span {
  color: var(--white);
  letter-spacing: 8px;
  text-transform: uppercase;
}

.banner-4-slider .content h2 {
  color: var(--white);
  text-transform: capitalize;
  margin-bottom: 20px;
  font-size: 100px;
  line-height: 110px;
}

.banner-4-slider .content .shape-2 {
  position: absolute;
  top: 25%;
  left: 160px;
}

.banner-4-slider .shape-1 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 700px;
  background-repeat: repeat-x;
  animation: slide 60s linear infinite;
}

.banner-4-dot {
  display: flex;
  right: 16%;
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateX(-50%);
  flex-direction: column;
}

.banner-4-dot .banner-custom-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-direction: column;
  width: 135px;
}

.banner-4-dot .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background: var(--white) !important;
  opacity: 1;
  margin-right: 10px;
}

.banner-4-dot .swiper-pagination-bullet-active {
  background: var(--theme-color) !important;
  position: relative;
  width: 10px;
  height: 10px;
  margin-top: 2px;
}

.banner-4-dot .swiper-pagination-bullet-active:before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  width: 14px;
  height: 14px;
  border: 1px solid var(--white);
  border-radius: 50%
}


@media only screen and (max-width: 1599px) {

  .banner-4-slider .content {
    padding: 265px 0 200px 0;
  }

  .banner-4-slider .content h1 {
    font-size: 70px;
    line-height: 80px;
  }

}

@media only screen and (max-width: 1299px) {

  .banner-4-slider .content {
    padding: 200px 0 100px 0;
  }

  .banner-4-slider .content h1 {
    font-size: 50px;
    line-height: 60px;
  }

  .banner-4-slider .content .shape-2 {
    position: absolute;
    top: 9%;
    left: 160px;
  }

}


@media only screen and (max-width: 991px) {

  .banner-4-slider .content {
    padding: 100px 0 100px 0;
  }

  .banner-4-slider .content h1 {
    font-size: 50px;
    line-height: 60px;
  }

  .banner-4-slider .shape-1,
  .banner-4-dot,
  .banner-4-slider .content .shape-2 {
    display: none;
  }

}


@media only screen and (max-width: 425px) {

  .banner-4-slider .content h1 {
    font-size: 36px;
    line-height: 46px;
  }

  .cta-3-section.style-2 .cta-3-main {
    margin-top: 60px;
  }

}

/*----------------------------------------*/
/*   COACHING 4 CSS START
/*----------------------------------------*/

.style-2 .coaching-2-active {
  margin-right: -450px;
  padding: 20px;
}

.coaching-section.style-2 {
  padding: 140px 0 0 0;
}

.style-2 .coaching-slider-navigation {
  justify-content: center;
  left: 285px;
  right: 265px;
  z-index: 1;
  gap: 385px;
}

/* .coaching-section.style-2:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 450px;
  background: #f5f5f5;
} */

.coaching-section.style-2 .shape_1 {
  position: absolute;
  top: -110px;
  left: 0;
  z-index: -1;
}

.coaching-section.style-2 .shape_2 {
  position: absolute;
  top: -112px;
  right: 0;
  z-index: -1;
}

@media only screen and (max-width: 1440px) {

  .coaching-section.style-2 .shape_1,
  .coaching-section.style-2 .shape_2 {
    width: 200px;
  }

  .style-2 .coaching-2-active {
    margin-right: -250px;
  }

  .style-2 .coaching-box-area {
    padding: 25px;
  }

  .style-2 .coaching-box-area h4 {
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 34px;
  }

  .style-2 .coaching-box-area .image:before {
    height: 225px;
  }

  .style-2 .coaching-slider-navigation {
    left: 58px;
    right: 180px;
    z-index: 1;
    gap: 350px;
  }

}

@media only screen and (max-width: 1399px) {

  .style-2 .coaching-box-area .image:before {
    height: 190px;
  }

  .style-2 .coaching-slider-navigation {
    left: 58px;
    right: 137px;
    z-index: 1;
    gap: 310px;
  }

}

@media only screen and (max-width: 1199px) {

  .coaching-section.style-2 .shape_1,
  .coaching-section.style-2 .shape_2,
  .style-2 .coaching-slider-navigation {
    display: none;
  }

  .style-2 .coaching-2-active {
    margin-right: -445px;
  }

}

@media only screen and (max-width: 991px) {

  .style-2 .coaching-2-active {
    margin-right: 0;
  }

}

/*----------------------------------------*/
/*   FIRM CSS START
/*----------------------------------------*/

.consulting-firm-section.style-3:before {
  display: none;
}

.consulting-firm-section.style-3 {
  padding-top: 435px;
  margin-top: -285px;
  background-repeat: no-repeat;
  background-position: center;
}

.style-3 .con-firm-content .icon-box-area .icon-box {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row-reverse;
  gap: 20px;
}

.style-3 .con-firm-content .icon-box-area .icon-box i {
  color: var(--gray);
}

.con-firm-content-4-image .image-box {
  position: absolute;
  top: -65px;
  right: 65px;
}

.con-firm-content-4-image .icon-box {
  width: 284px;
  height: 284px;
  background: var(--theme-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  bottom: -55px;
  right: 55px;
}

.con-firm-content-4-image .icon-box:before {
  content: "";
  position: absolute;
  top: 23px;
  left: 23px;
  width: 235px;
  height: 235px;
  border: 1px dashed var(--white);
  border-radius: 50%;
  animation: spin 40s linear infinite;
}

/* hover effects */

.con-firm-content-4-image .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  mix-blend-mode: darken;
}

.con-firm-content-4-image .image img {
  position: relative;
  width: 100%;
  display: block;
  -webkit-transition: all 700ms ease;
  transition: all 700ms ease;
}

.con-firm-content-4-image .image img:first-child {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  transform: scale(0.5);
  opacity: 0;
  -webkit-filter: blur(10px);
  filter: blur(10px);
}

.con-firm-content-4-image:hover .image {
  position: relative;
  overflow: hidden;
}

.con-firm-content-4-image:hover .image img:first-child {
  transform: scale(1);
  opacity: 1;
  -webkit-filter: blur(0);
  filter: blur(0);
}

/* hover effects end*/

@media only screen and (max-width: 1299px) {

  .con-firm-content-4-image .image-box img {
    max-width: 200px;
  }

  .con-firm-content-4-image .icon-box {
    width: 240px;
    height: 240px;
  }

  .con-firm-content-4-image .icon-box:before {
    top: 14px;
    left: 14px;
    width: 212px;
    height: 212px;
  }

  .coaching-section.style-2 {
    padding: 70px 0 0 0;
  }

}

@media only screen and (max-width: 991px) {

  .con-firm-content-4-image .image-box,
  .con-firm-content-4-image .icon-box {
    display: none;
  }

  .con-firm-content-4-image {
    padding: 0 0 30px 0;
  }

  .consulting-firm-section.style-3 {
    padding-top: 435px;
    margin-top: -350px;
  }

  .consulting-firm-section.style-3 {
    background-size: cover;
  }

}

/*----------------------------------------*/
/*   COUNTRY LIST 3 CSS START
/*----------------------------------------*/

.country-list-3-box {
  padding: 40px 50px;
  background: #fff;
  border: 1px solid #E3DBD8;
  transition: all 700ms ease;
}

.country-list-3-box:hover {
  box-shadow: 0px 4px 30px 0px #5555551A;
}

.country-list-3-box h4:hover {
  color: var(--theme-color);
}

.country-list-3-box hr {
  margin-top: 25px;
  margin-bottom: 15px;
}

.country-list-3-box ul {
  margin-top: 15px;
}

.country-list-3-box ul li {
  margin-left: 25px;
  margin-bottom: 5px;
  position: relative;
}

.country-list-3-box ul li:before {
  content: "\e931";
  font-family: 'icomoon';
  position: absolute;
  top: 0;
  left: -25px;
  color: var(--gray);
}

.country-3-active {
  margin-right: -315px;
}

.country-3-slider-navigation {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1296px;
  position: relative;
  gap: 10px;
}

.country-3-slider-navigation button {
  width: 20px;
  height: 20px;
  font-size: 10px;
  color: #E3DBD8;
  border: 1px solid #E3DBD8;
  background: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  transition: all 700ms ease;
}

.country-3-slider-navigation button:hover {
  background: var(--theme-color);
  color: var(--white);
}

.country-3-slider-navigation:before {
  content: "";
  position: absolute;
  top: 9px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #E3DBD8;
}

.country-list-3-section .shape-1 {
  position: absolute;
  top: 0;
  left: 0;
}

.country-list-3-section .shape-2 {
  position: absolute;
  top: -100px;
  right: 30px;
  animation: shake 20s linear infinite;
}

@media only screen and (max-width: 1399px) {

  .country-3-slider-navigation {
    max-width: 1100px;
  }

}

@media only screen and (max-width: 1199px) {

  .country-3-slider-navigation,
  .country-list-3-section .shape-2 {
    display: none;
  }

}

@media only screen and (max-width: 767px) {

  .country-3-active {
    margin-right: 0;
  }

  .country-list-3-box {
    padding: 20px;
  }

}

/*----------------------------------------*/
/*   ICON COUNTER BOX CSS START
/*----------------------------------------*/

.icon-counter-4-box {
  margin-bottom: 15px;
}

.icon-counter-4-box.text-center i {
  font-size: 60px;
  color: var(--theme-color);
}

.icon-counter-4-box h2 {
  font-size: 48px;
  color: var(--theme-color);
  margin-bottom: 10px;
}

.icon-counter-4-box h2 span {
  color: var(--black);
}

.icon-counter-section .shape-1 {
  position: absolute;
  top: -50px;
  right: 0;
}

@media only screen and (max-width: 1599px) {

  .icon-counter-section .shape-1 {
    width: 200px;
  }

}

@media only screen and (max-width: 991px) {

  .icon-counter-section .shape-1 {
    display: none;
  }

}


/*----------------------------------------*/
/*   APPLY ONLINE AREA CSS START
/*----------------------------------------*/

.apply-online-box:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background: #D81B3DCC;
  transition: all 700ms ease;
}

.apply-online-box:nth-child(odd)::before {
  background: #E20935CC;
}

.apply-online-box:hover:before {
  left: 0;
  width: 100%;
}

.apply-online-box .content {
  position: absolute;
  bottom: 60px;
  left: 60px;
  right: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  transition: all 700ms ease;
}

.apply-online-box:hover .content {
  opacity: 1;
}

.apply-online-box .content h4 {
  font-size: 30px;
  line-height: 40px;
  color: var(--white);
}

.apply-online-box .content i {
  font-size: 30px;
  color: var(--white);
}

.apply_row {
  display: flex;
  gap: 20px;
}

.apply_row_1 {
  display: flex;
  gap: 20px;
}

.apply_col-lg-6.one {
  padding-bottom: 0px;
}

@media only screen and (max-width: 1440px) {

  .apply-online-box .content h4 {
    font-size: 21px;
    line-height: 30px;
  }

  .apply-online-box .content {
    left: 30px;
    right: 30px;
  }

}

@media only screen and (max-width: 991px) {

  .apply_row {
    flex-direction: column;
  }

}

@media only screen and (max-width: 575px) {

  .apply_row_1 {
    flex-direction: column;
  }

  .sidebar__toggle {
    display: inline;
  }
}

/*----------------------------------------*/
/*   VISA SERVICES 4 AREA CSS START
/*----------------------------------------*/

.visa-services-4-box {
  position: relative;
  height: 440px;
  padding: 15px;
  border: 1px dashed #B0B0B0;
  border-radius: 10px;
  transition: all 700ms ease;
}

.visa-services-4-box:hover {
  box-shadow: 0px 4px 40px 0px #A6A6A640;
}

.visa-services-4-box .content {
  padding: 21px 15px 36px 15px;
  position: absolute;
  bottom: 0;
}

.visa-services-4-box .content h4 {
  margin-bottom: 10px;
}

.visa-services-4-box .sev-btn {
  margin-top: 10px;
  display: flex;
  justify-content: start;
  align-items: baseline;
  gap: 7px;
  font-size: 18px;
  font-family: var(--cormorant-font);
  font-weight: 500;
  transition: all 700ms ease;
}

.visa-services-4-box .sev-btn i {
  font-size: 13px;
  font-weight: 800;
  transition: all 700ms ease;
}

.visa-services-4-box:hover .sev-btn {
  color: var(--theme-color);
}

.visa-services-4-box .sev-btn:before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  width: 93px;
  height: 100%;
  border-bottom: 1px dashed;
}

.visa-services-4-box .image {
  position: absolute;
  top: -20px;
  left: 15px;
  right: 15px;
  transition: all 700ms ease;
}

.visa-services-4-box .image img {
  height: 272px;
  border-radius: 5px;
  transition: all 700ms ease;
}

.visa-services-4-box:hover .image img {
  height: 236px;
}

.visa-services-4-box:hover .image {
  top: 15px;
}

.visa-services-4-section .visa-2-active {
  padding: 20px 0 0;
}

.visa-services-4-section .shape-1 {
  position: absolute;
  top: 0;
  left: 0;
}

.visa-services-4-box .icon-box {
  width: 76px;
  height: 76px;
  background: var(--black);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 40px;
  transition: all 700ms ease;
  margin-bottom: 25px;
}

.visa-services-4-box:hover .icon-box {
  background: var(--theme-color);
  transform: scaleX(-1);
}

@media only screen and (max-width: 1399px) {

  .visa-services-4-box .image img {
    height: 250px;
  }

  .visa-services-4-box:hover .image img {
    height: 215px;
  }

  .visa-services-4-box .icon-box {
    width: 60px;
    height: 60px;
    font-size: 25px;
    margin-top: 18px;
  }

}

@media only screen and (max-width: 1199px) {

  .visa-services-4-box .image img {
    height: 270px;
  }

  .visa-services-4-box:hover .image img {
    height: 235px;
  }

}

/*----------------------------------------*/
/*  CHOOSE 4 AREA CSS START
/*----------------------------------------*/

.choose-4-section .shape-1 {
  position: absolute;
  top: 0;
  left: 0;
}

.choose-4-section .shape-2 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.choose-4-content-area .section-title,
.choose-4-content-area .title-box p {
  color: var(--white);
}

.choose-4-content-area .icon-box-area {
  position: relative;
  padding: 22px 20px;
  border: 1px dashed #495567;
  border-radius: 5px;
  margin-bottom: 15px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 50px;
  background: #D81B3D;
}

.choose-4-content-area .icon-box-area:last-child {
  margin-bottom: 0;
}

.choose-4-content-area .icon-box-area .icon-box {
  position: relative;
  font-size: 50px;
  color: var(--theme-color);
}

.choose-4-content-area .icon-box-area .icon-box:before {
  content: "";
  position: absolute;
  top: -7px;
  right: -20px;
  width: 1px;
  height: 65px;
  background: #495567;
}

.choose-4-content-area .content span {
  font-family: 'Rubik';
}

.choose-4-content-area .content h5 {
  color: var(--white);
  font-size: 24px;
  margin-top: 5px;
}

.choose-4-section .image {
  padding-left: 165px;
  padding-top: 70px;
  margin-right: -315px;
}

.choose-4-section:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 422px;
  height: 70px;
  background: #fff;
}

@media only screen and (max-width: 1790px) {

  .choose-4-section .shape-1 {
    width: 140px;
  }

}

@media only screen and (max-width: 1560px) {

  .choose-4-section .shape-1 {
    display: none;
  }

}

@media only screen and (max-width: 1399px) {

  .choose-4-section .image {
    padding-left: 80px;
    padding-top: 70px;
    margin-right: -315px;
  }

  .choose-4-section:before {
    width: 250px;
  }

}

@media only screen and (max-width: 1199px) {

  .choose-4-section .image {
    padding-left: 40px;
    padding-top: 70px;
    margin-right: -315px;
  }

  .choose-4-content-area {
    padding: 60px 0;
  }

}


@media only screen and (max-width: 991px) {

  .choose-4-section .image {
    padding-left: 0;
    padding-top: 0;
    margin-right: 0;
  }

  .choose-4-content-area .icon-box-area {
    padding: 20px 15px;
    gap: 35px;
  }

  .choose-4-content-area .content h5 {
    color: var(--white);
    font-size: 21px;
    margin-top: 5px;
  }

  .choose-4-section:before {
    display: none;
  }

}

/*----------------------------------------*/
/*  BOOKING AREA CSS START
/*----------------------------------------*/

.ba-section .booking-form--wrapper {
  position: relative;
  background: transparent;
  border-radius: 0;
  padding: 106px 185px 70px 312px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
  margin-bottom: -1px;
}

.ba-section .booking-form--wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #032353D9;
}

.ba-section .ba-map iframe {
  height: 591px;
  width: 100%;
  margin-bottom: -8px;
}

.ba-section .ba-map {
  margin-left: -150px;
}

.ba-section .bg-top-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background: var(--white);
}

.ba-section .bg-top-overlay:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 34px;
  height: 41px;
  background: #1C263A;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}

.ba-section .booking--button .btn {
  background: var(--theme-color);
  color: var(--white);
}

@media only screen and (max-width: 1870px) {

  .ba-section .booking-form--wrapper {
    padding: 106px 185px 70px 225px;
  }

}

@media only screen and (max-width: 1699px) {

  .ba-section .booking-form--wrapper {
    padding: 106px 120px 70px 200px;
  }

}

@media only screen and (max-width: 1521px) {

  .ba-section .booking-form--wrapper {
    padding: 106px 120px 70px 140px;
  }

}

@media only screen and (max-width: 1401px) {

  .booking-form--wrapper h2 {
    font-size: 36px;
    line-height: 46px;
  }

  .ba-section .booking-form--wrapper {
    padding: 118px 120px 70px 140px;
  }

}

@media only screen and (max-width: 1299px) {

  .booking-form--wrapper .form-group.book_time:before,
  .booking-form--wrapper .form-group.book_date:after,
  .ba-section .booking-form--wrapper .common .nice-select:after {
    right: 0;
  }

  .ba-section .ba-map iframe {
    height: 571px;
  }

}

@media only screen and (max-width: 1199px) {

  .ba-section .ba-map {
    margin-left: -60px;
  }

}

@media only screen and (max-width: 1181px) {

  .ba-section .booking-form--wrapper {
    padding: 118px 110px 70px 100px;
  }

}

@media only screen and (max-width: 1081px) {

  .ba-section .booking-form--wrapper {
    padding: 118px 110px 70px 90px;
  }

}

@media only screen and (max-width: 1061px) {

  .ba-section .booking-form--wrapper {
    padding: 118px 80px 70px 60px;
  }

}

@media only screen and (max-width: 991px) {

  .ba-section .booking-form--wrapper {
    clip-path: none;
  }

  .choose-4-section .image {
    display: none;
  }

}

@media only screen and (max-width: 767px) {

  .ba-section .booking-form--wrapper {
    padding: 90px 30px 60px 30px;
  }

  .booking-form--wrapper h2 {
    font-size: 32px;
    line-height: 42px;
  }

  .blog-section-2 .blog-style-1 {
    gap: 0;
    flex-direction: column;
  }

  .blog-section-2 .blog-style-1 .date-excerpt {
    top: -62px;
    bottom: inherit;
    left: 60px;
  }

}

.training-3-main .left-box img {
  border-radius: 10px;
}

/*----------------------------------------*/
/*  DARK HOME CSS START
/*----------------------------------------*/

.dark_style,
.dark_style .sticky,
.dark_style .main-menu ul li .submenu,
.dark_style .country-3-list-box,
.dark_style .services-section.style-2 .services-box,
.dark_style .footer-main {
  background: var(--dark);
}

.dark_style .sidebar__toggle,
.dark_style .main-menu ul li .submenu li:hover>a {
  color: #5D666F;
}

.dark_style .main-menu ul li a,
.dark_style .main-menu ul li .submenu li a,
.dark_style .header-call-us .contact-num span,
.dark_style .header-call-us .contact-num h5,
.dark_style .banner-3-content .text-1,
.dark_style .section-title,
.dark_style .visa-3-middle-area .text-1,
.dark_style p,
.dark_style .visa-3-left-area .icon-box-area .icon-box h4,
.dark_style .text-slider-box .slide-box h2,
.dark_style .work-3-process-box h5,
.dark_style .services-box .content h4,
.dark_style .services-box .icon-box-top,
.dark_style .services-box .content .service-btn,
.dark_style .choose-3-box h5,
.dark_style .style-2 .faq-box-area *,
.dark_style .style-2 .faq-box-area .accordion-button:not(.collapsed),
.dark_style .style-2 .booking-area p,
.dark_style .style-2 .faq-conter-box .inner h2,
.dark_style .style-2 .faq-conter-box .inner h5,
.dark_style .training-3-main h4,
.dark_style .training-3-main .right-box ul li,
.dark_style .training-3-main .training-price h2,
.dark_style .training-3-main .training-price span,
.dark_style .testimonials-box .content h5,
.dark_style .blog-style-1 h4,
.dark_style .country-3-list-box h4,
.dark_style .country-3-list-box ul li {
  color: var(--white);
}

.dark_style .choose-3-box h5:hover {
  color: var(--theme-color);
}

.dark_style .primary-btn-2 {
  color: var(--theme-color);
  border: 1px solid var(--white);
}

.dark_style .banner-3-container .icon-box {
  border: 15px solid var(--dark);
}

.dark_style .banner-section-3 .shape-1 {
  position: absolute;
  top: inherit;
  bottom: -200px;
  right: 0;
}

.dark_style .visa-3-cat-section .shape-2-d {
  position: absolute;
  top: -100px;
  left: 0;
}

.dark_style .country-3-list-box {
  border-right: 1px solid #303030;
}

.dark_style .country-1-active {
  border: 1px solid #303030;
}

.dark_style .country-3-list-section .shape-4 {
  position: absolute;
  top: 220px;
  right: 0;
}

.dark_style .style-2 .text-slider-box .slide-box:nth-child(even) h2 {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--white);
  color: transparent;
}

.dark_style .work-3-process-box p {
  color: var(--gray);
}

.dark_style .services-section.style-2 .services-box {
  border: 1px solid #383838
}

.dark_style .choose-3-section .image,
.dark_style .blog-style-1 .image {
  mix-blend-mode: exclusion;
}

.dark_style .choose-3-box {
  background: var(--dark);
  border: 1px solid #383838
}

.dark_style .choose-3-box .icon-box {
  background: var(--theme-color);
}

.dark_style .choose-3-box .icon-box:before {
  background: var(--color-1);
}

.dark_style .faq-box-area .accordion-item {
  border: 1px solid #FFFFFF33;
}

.dark_style .faq-section.style-2:before {
  background: #0f0f0f;
}

.dark_style .style-2 .faq-conter-box .inner {
  border: 1px solid #303030;
}

.dark_style .booking--button .btn {
  background: var(--white);
  color: var(--black);
}

.dark_style .training-3-main {
  border: 1px solid #383838;
  background: var(--dark);
}

.dark_style .testimonials-box {
  background: var(--dark);
  border: 1px solid #383838
}

.dark_style .testimonials-box .content ul li {
  width: 27px;
  height: 27px;
  border-radius: 5px;
  font-size: 17px;
  background: var(--theme-color);
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.dark_style .blog-style-1 {
  box-shadow: none;
  border: 1px solid #383838;
}

.dark_style .bar_line {
  background: #303030;
}

.dark_style .header-two .header-main {
  border-bottom: 1px solid #303030;
}

.dark_style .country-3-list-section .float-text h2 {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #d4d4d421;
  color: transparent;
  font-size: 100px;
  line-height: 110px;
  position: absolute;
  top: 0;
  letter-spacing: 8px;
}

.dark_style .banner-3-content .text-2 {
  -webkit-text-stroke-color: #4F4D4D;
}


/*----------------------------------------*/
/*  ABOUT PAGE CSS START
/*----------------------------------------*/

.breadcrumb__area {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 40vh;
  background-image: linear-gradient(90deg, rgba(225, 56, 51, 0.8) 0%, rgba(29, 40, 81, 0.36) 102.55%), url('../../../public/assets/imgs/page-title/pt-shape.png');
}

.breadcrumb__area1 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 40vh;
  background-image: url('../../../public/assets/imgs/page-title/Frame\ 15.png');
}

.breadcrumb__area2 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 40vh;
  background-image: linear-gradient(90deg, rgba(225, 56, 51, 0.8) 0%, rgba(29, 40, 81, 0.36) 102.55%), url('../../../public/assets/imgs/page-title/work.png');
}

.breadcrumb__area3 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 40vh;
  background-image: linear-gradient(90deg, rgba(225, 56, 51, 0.8) 0%, rgba(29, 40, 81, 0.36) 102.55%), url('../../../public/assets/imgs/page-title/home.png');
}

.breadcrumb__area .breadcrumb__wrapper {
  padding: 100px 0;
}

.breadcrumb__area .shape-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.breadcrumb__area .shape-1 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: slide 60s linear infinite;
}

.breadcrumb__area .shape-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.breadcrumb__title {
  font-size: 48px;
  line-height: 58px;
  color: var(--white);
}

.breadcrumb__menu ul {
  display: flex;
  align-items: start;
  gap: 30px;
  color: var(--white);
  letter-spacing: 8px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
}

.breadcrumb__menu ul li:before {
  content: "/";
  position: relative;
  top: 0;
  left: -20px;
}

.breadcrumb__menu ul li:first-child::before {
  display: none;
}


@media only screen and (max-width: 1399px) {

  .breadcrumb__area .breadcrumb__wrapper {
    padding: 160px 0 110px 0;
  }

}

@media only screen and (max-width: 991px) {

  .breadcrumb__area .breadcrumb__wrapper {
    padding: 100px 0;
  }

  .breadcrumb__title {
    font-size: 32px;
    line-height: 42px;
  }

  .breadcrumb__menu ul {
    letter-spacing: 2px;
  }

}


.about-page-section .content-area {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 30px;
}

.about-page-section .icon-box-area .icon-box:hover i {
  color: var(--color-1);
}

.about-page-section .icon-box-area .icon-box:hover h4 {
  color: var(--theme-color);
}

.about-page-section .icon-box-area .icon-box {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row-reverse;
  gap: 40px;
}

.about-page-section .icon-box-area .icon-box i {
  font-size: 45px;
  color: var(--theme-color);
  transition: all 700ms ease;
}

.about-page-section .icon-box-area p {
  font-family: var(--rubik-font);
}

.about-page-section .text-1 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  font-family: 'Cormorant';
  text-transform: capitalize;
}


.about-page-section .play-box {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 30px;
}

.about-page-section .play-btn a {
  width: 126px;
  height: 126px;
  background: var(--black);
  color: var(--white);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--cormorant-font);
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all 700ms ease;
}

.about-page-section .play-btn a:hover {
  background: var(--theme-color);
  color: var(--white);
}

.about-page-section .play-content i {
  font-size: 58px;
  color: var(--white);
}

.about-page-section .play-content h5 {
  font-size: 24px;
  line-height: 34px;
  text-transform: capitalize;
}

.about-page-section .play-content .one {
  font-size: 36px;
  color: var(--theme-color);
}

.about-page-section .play-content .two {
  font-size: 36px;
  color: var(--black);
}

.about-page-section .play-content h5:last-child {
  color: var(--black);
}

.about-page-right {
  display: flex;
  gap: 20px;
  padding-left: 10px;
}

.about-page-right .box-1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.about-page-right .box-2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 25px;
}

.about-page-right .image img {
  border-radius: 10px;
}

.about-page-right .icon-box {
  width: 284px;
  height: 284px;
  background: var(--theme-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  bottom: -55px;
  right: 55px;
  border: 10px solid #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about-page-right .icon-box:before {
  content: "";
  position: absolute;
  top: 13px;
  left: 13px;
  width: 235px;
  height: 235px;
  border: 1px dashed var(--white);
  border-radius: 50%;
  animation: spin 40s linear infinite;
}

.benefits-left-area.style-2 .icon-box *,
.benefits-left-area.style-2 .title-box h2,
.benefits-left-area.style-2 .title-box p {
  color: var(--white);
}

.benefits-left-area.style-2 .icon-box {
  border: 1px solid #FFFFFF26;
}

.benefits-left-area.style-2 .icon-area {
  background: #F6F6F633;
}

.benefits-page-section .image {
  margin-right: -315px;
  padding-left: 100px;
  position: relative;
}

.benefits-page-section .shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-width: 1399px) {

  .benefits-left-area.style-2 {
    padding-right: 0;
  }

}

@media only screen and (max-width: 1199px) {

  .about-page-section .icon-box-area .icon-box h4 {
    font-size: 22px;
    line-height: 32px;
  }

}

@media only screen and (max-width: 991px) {

  .about-page-right {
    padding-top: 30px;
  }

  .benefits-page-section .image {
    display: none;
  }

  .benefits-left-area.style-2 {
    padding-right: 0;
    padding-bottom: 60px;
  }

}

@media only screen and (max-width: 767px) {

  .about-page-right .icon-box {
    display: none;
  }

  .about-page-section .content-area {
    display: flex;
    justify-content: start;
    align-items: flex-start;
    gap: 30px;
    flex-direction: column;
  }

}

.icon-counter-section.two .shape-1 {
  position: absolute;
  top: 0;
  right: 0;
}

/*----------------------------------------*/
/*  TEAM DETAILS PAGE CSS START
/*----------------------------------------*/

.team-d-image-area .info-list li a {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
}

.team-d-image-area .info-list li i {
  font-size: 20px;
  color: var(--theme-color)
}

.team-d-image-area ul.socials-info {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 15px;
  margin-top: 30px;
}

.team-d-image-area ul.socials-info li {
  font-size: 30px;
  color: var(--black);
}

.team-d-image-area ul.socials-info li:hover {
  color: var(--theme-color);
}

.team-d-info-details .auth-name {
  font-size: 36px;
  line-height: 46px;
  margin-bottom: 10px;
}

.team-d-info-details .text-1 {
  font-size: 24px;
  line-height: 34px;
  font-family: 'Cormorant';
  font-weight: 700;
  color: var(--black);
}

.team-d-info-details .top-reason {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 45px;
}

.team-d-info-details .top-reason:before {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 526px;
  height: 1px;
  background: #5D666F26;
}

.team-d-info-details ul.tr-list li {
  margin-left: 15px;
  margin-bottom: 15px;
}

.team-d-info-details ul.tr-list li:before {
  content: "\e90b";
  font-family: 'icomoon';
  position: relative;
  top: 4px;
  left: -15px;
  font-size: 20px;
  color: var(--theme-color);
}

.team-d-info-details ul.tr-list li i {
  font-size: 20px;
}

.team-d-info-details .tr-list {
  margin-bottom: 40px;
}

.team-d-info-details .content-area {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
}

.team-d-info-details .icon-box {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.team-d-info-details .icon-box i {
  font-size: 45px;
}

.team-d-info-details .icon-box-area p {
  font-family: 'Rubik';
}

.team-d-info-details .booking-form--wrapper {
  padding: 55px 70px;
  margin-left: -60px;
  border: 1px solid #5D666F26;
  background: #F2F2F2;
}

.team-d-info-details .booking-form--wrapper ::placeholder,
.team-d-info-details .booking-form--wrapper h2,
.team-d-info-details .booking-form--wrapper input,
.team-d-info-details .booking-form--wrapper .form-group.book_date:before,
.team-d-info-details .booking-form--wrapper .form-group.book_time:before {
  color: var(--black);
}

.team-d-info-details .booking-form--wrapper .common .nice-select,
.team-d-info-details .booking-form--inner input[type=text],
.team-d-info-details .booking-form--inner input[type=tel] {
  color: rgb(216, 6, 33);
  border-bottom: 1px solid var(--black);
}

.team-d-info-details .booking--button .btn {
  background: var(--theme-color);
  color: var(--white);
}


@media only screen and (max-width: 1199px) {

  .team-d-info-details {
    padding-left: 0;
  }

  .team-d-info-details .booking-form--wrapper {
    margin-left: 0;
    padding: 30px 20px;
  }

  .team-d-info-details .content-area {
    flex-direction: column;
  }

}

@media only screen and (max-width: 991px) {

  .team-d-info-details .auth-name {
    margin-top: 30px;
  }

  .team-d-info-details .content-area {
    align-items: flex-start;
  }

}

/*----------------------------------------*/
/*  STORY DETAILS PAGE CSS START
/*----------------------------------------*/

.story-section {
  padding-right: 110px;
  padding-left: 110px;
}

.story-section h2 {
  font-size: 48px;
  line-height: 58px;
  margin-top: 60px;
}

.story-section .text-1 {
  font-size: 24px;
  line-height: 34px;
  font-family: 'Cormorant';
  font-weight: 700;
  color: var(--black);
}

.story-section ul li {
  margin-left: 15px;
}

.story-section ul li:before {
  content: ".";
  position: relative;
  top: -3px;
  left: -15px;
  font-size: 30px;
}

.story-section .shape-1 {
  position: absolute;
  top: 0;
  left: -290px;
}

.story-section .shape-2 {
  position: absolute;
  bottom: 0;
  left: 0;
}

@media only screen and (max-width: 767px) {

  .story-section {
    padding-right: 20px;
    padding-left: 20px;
  }

  .story-section h2 {
    font-size: 32px;
    line-height: 42px;
    margin-top: 30px;
  }

}


/*----------------------------------------*/
/*  GALLERY PAGE CSS START
/*----------------------------------------*/

.portfolio-filter-section ul.post-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  font-family: var(--cormorant-font);
  font-size: 20px;
  font-weight: 700;
}

.portfolio-filter-section ul.post-filter li {
  cursor: pointer;
}

.portfolio-filter-section ul.post-filter li.active {
  color: var(--black);
}

@media only screen and (max-width: 767px) {

  .portfolio-filter-section ul.post-filter {
    gap: 30px;
    flex-wrap: wrap;
  }
}

.portfolio-block-three:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background: #e209357d;
  transition: all 700ms ease;
}

.portfolio-block-three:hover:before {
  width: 100%;
  left: 0;
}

.portfolio-block-three i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  color: var(--white);
  opacity: 0;
  transition: all 700ms ease;
}

.portfolio-block-three:hover i {
  opacity: 1;
}


/*----------------------------------------*/
/*  FAQ PAGE CSS START
/*----------------------------------------*/

.welcome-look .container {
  padding: 90px 50px 10px 110px;
}

.welcome-look .image {
  padding-left: 150px;
}

section.welcome-look h2 {
  font-size: 48px;
  line-height: 58px;
}

.welcome-look p {
  padding: 20px 0;
}

.welcome-look .form-area button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  font-size: 24px;
}

.welcome-look .btn-area {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 20px;
  gap: 30px;
}

.question-left {
  padding: 60px 105px 97px 60px;
  background: var(--theme-color);
  border-radius: 10px;
}

.question-left h4 {
  color: var(--white);
  font-size: 24px;
  line-height: 34px;
}

.question-left .icon-box {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.question-left .icon-box span {
  font-size: 14px;
  line-height: 24px;
  color: var(--white);
  font-family: var(--rubik-font);
}

.question-left .icon-box h5 {
  font-size: 22px;
  line-height: 32px;
  color: var(--white);
}

.question-right.style-2 {
  padding-left: 130px;
}


@media only screen and (max-width: 1399px) {

  .welcome-look .container {
    padding: 90px 50px 60px 110px;
  }

}

@media only screen and (max-width: 1199px) {

  .welcome-look .image {
    padding-left: 50px;
  }

  .question-right.style-2 {
    padding-left: 0;
  }

  .question-left {
    padding: 30px;
  }

}

@media only screen and (max-width: 991px) {

  .welcome-look .image {
    padding-left: 0;
  }

  .welcome-look .container {
    padding: 60px 15px;
  }

  .question-left {
    margin-bottom: 30px;
  }

  .questions-section {
    padding-left: 15px;
    padding-right: 15px;
  }

}

/*----------------------------------------*/
/*  404 PAGE CSS START
/*----------------------------------------*/

.error-container {
  max-width: 636px;
  margin: 0 auto;
  padding: 0 15px;
}

.error-container .image {
  margin-top: -105px;
}

.error-container .content h2 {
  font-size: 48px;
  line-height: 58px;
  text-transform: capitalize;
}

.error-container .content h5 {
  font-size: 24px;
  line-height: 34px;
  text-transform: capitalize;
}

.error-container .form-area input {
  background: #F3F3F3;
}

.error-container .form-area {
  max-width: 526px;
  margin: 40px auto;
}

.error-container .form-area button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  font-size: 24px;
  line-height: 0;
}


@media only screen and (max-width: 425px) {

  .error-container .image {
    margin-top: -65px;
  }

  .error-container .content h2 {
    font-size: 36px;
    line-height: 46px;
  }

}

/*----------------------------------------*/
/*  COACHING DETAILS CSS START
/*----------------------------------------*/

.coaching-details-section aside {
  background: #F2F2F2;
  padding: 30px 20px;
}

.coaching-widget h4 {
  padding: 20px 30px;
  background: var(--black);
  color: var(--white);
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  margin-bottom: 20px;
}

.coaching-widget ul.coaching-list li {
  padding: 18px 30px;
  font-size: 20px;
  line-height: 30px;
  font-family: 'Cormorant';
  font-weight: 700;
  background: var(--white);
  box-shadow: 0px 20px 60px 0px #7373731A;
  margin-bottom: 13px;
  border-radius: 5px;
}

.coaching-widget ul.coaching-list li.active,
.coaching-widget ul.coaching-list li:hover {
  background: var(--theme-color);
  color: var(--white);
}

.coaching-widget ul.coaching-list li:last-child {
  margin-bottom: 0;
}

.coaching-widget ul.coaching-list li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coaching-widget ul.coaching-list li {
  color: var(--black);
  font-weight: 700;
}

.coaching-widget ul.coaching-list li a i {
  font-size: 10px;
  font-weight: 700;
}

.faltu-widgets:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(148.72deg, rgba(0, 0, 0, 0) 13.92%, rgba(0, 37, 92, 0.6) 66.92%);
}

.faltu-widgets .icon-box {
  padding: 150px 85px 150px 20px;
  background: var(--white);
  max-width: 180px;
  font-size: 76px;
  clip-path: polygon(100% 50%, 0 0, 0 100%);
  position: relative;
  z-index: 3;
}

.faltu-widgets .icon-box:before {
  content: "";
  position: absolute;
  top: -3px;
  left: -5px;
  width: 100%;
  height: 100%;
  clip-path: polygon(100% 50%, 0 0, 0 100%);
  background: #D81B3D;
  z-index: -1;
}

.faltu-widgets .content {
  position: absolute;
  bottom: 30px;
  right: 50px;
}

.faltu-widgets .content h5 {
  color: var(--white);
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 10px;
}

.faltu-widgets .content ul li {
  color: var(--white);
  position: relative;
  margin-left: 15px;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 5px;
}

.faltu-widgets .content ul li:before {
  content: "\e90d";
  position: relative;
  top: 0;
  left: -10px;
  font-family: 'icomoon';
  font-size: 10px;
}

.faltu-widgets-2 {
  background: #D81B3D;
}

.faltu-widgets-2 h5 {
  color: var(--white);
  padding: 30px 20px 215px 160px;
  position: relative;
}

.faltu-widgets-2 .image-1 {
  position: absolute;
  top: 15px;
  left: -5px;
}

.faltu-widgets-2 .image-2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 35px;
}

.faltu-widgets-2 .image-3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.coaching-d-content-area .title {
  font-size: 48px;
  line-height: 58px;
}

.coaching-d-content-area .tab-box-area .image-box {
  display: flex;
  gap: 20px;
}

.coaching-d-content-area .tab-box-area .content h5 {
  padding: 40px 0;
  font-size: 24px;
  line-height: 34px;
  text-transform: capitalize;
}

.coaching-d-content-area .tab-box-area li.nav-item {
  border-radius: 0;
  border: none;
}

.coaching-d-content-area .tab-box-area .nav-tabs .nav-link {
  border-radius: 0;
  border: 0;
  font-size: 24px;
  font-family: 'Cormorant';
  line-height: 24px;
  font-weight: 700;
  color: var(--black);
  padding-bottom: 15px;
}

.coaching-d-content-area .tab-box-area .nav-tabs .nav-link.active {
  border-bottom: 1px solid var(--theme-color);
}

.coaching-d-content-area .tab-box-area .nav-tabs {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 50px;
  border-bottom: 1px solid #5D666F66;
}


@media only screen and (max-width: 1399px) {

  .coaching-widget h4 {
    font-size: 24px;
    line-height: 34px;
  }

  .coaching-d-content-area {
    padding-left: 60px;
  }

  .faltu-widgets .content {
    position: absolute;
    bottom: 15px;
    right: 20px;
  }

  .faltu-widgets-2 h5 {
    padding: 30px 20px 215px 115px;
  }

}


@media only screen and (max-width: 1199px) {

  .faltu-widgets .icon-box {
    padding: 150px 85px 150px 20px;
    background: var(--white);
    max-width: 95px;
    font-size: 40px;
    clip-path: polygon(100% 50%, 0 0, 0 100%);
    position: relative;
    z-index: 3;
  }

  .coaching-d-content-area .tab-box-area .nav-tabs {
    gap: 15px;
    flex-wrap: wrap;
  }

}

@media only screen and (max-width: 991px) {

  .faltu-widgets,
  .faltu-widgets-2 {
    display: none;
  }

  .choose-3-content.style-3 {
    margin-left: 0;
  }

  .coaching-d-content-area {
    padding-left: 0;
    padding-top: 60px;
  }

}

@media only screen and (max-width: 575px) {
  .sidebar__toggle {
    display: inline;
  }

  .coaching-d-content-area .tab-box-area .nav-tabs .nav-link {
    font-size: 18px;
    padding: 8px;
  }

  .coaching-d-content-area .tab-box-area .content h5 {
    padding: 25px 0;
    font-size: 18px;
    line-height: 28px;
  }

  .coaching-d-content-area .title {
    font-size: 36px;
    line-height: 46px;
  }

  .coaching-d-content-area .tab-box-area .nav-tabs {
    gap: 0px;
  }

  .coaching-d-content-area .tab-box-area .image-box {
    flex-direction: column-reverse;
  }

}

/*----------------------------------------*/
/* CONTACT PAGE CSS START
/*----------------------------------------*/

.contact-page-section .contact-form {
  margin-left: -10px;
}

.contact-page-section .contact-input {
  padding: 5px;
}

.contact-page-section .contact-form textarea {
  height: 220px;
  resize: none;
}

.contact-page-section .contact-icon-box {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
}

.contact-page-section .contact-icon-box .icon-box {
  font-size: 30px;
  background: var(--theme-color);
  color: var(--white);
  padding: 15px;
}

.contact-page-section .contact-icon-box h4 {
  font-size: 25px;
  line-height: 35px;
  font-weight: 600;
}

.contact-page-section .contact-icon-box a {
  color: var(--black);
}

.contact-page-section .contact-icon-box a:hover {
  color: var(--theme-color);
}

.contact-page-section .contact-input input,
.contact-page-section .contact-input textarea {
  background: #fff;
}

.contact-map iframe {
  height: 450px;
  width: 100%;
  margin-bottom: -8px;
}

@media only screen and (max-width: 991px) {

  .contact-page-section .contact-page-info-area {
    padding: 60px 0 0;
  }

}

/*----------------------------------------*/
/* BLOG DETAILS CSS START
/*----------------------------------------*/

.blog-d-image-area .date-meta {
  position: absolute;
  bottom: -29px;
  right: 30px;
  text-align: center;
  background: var(--color-1);
}

.blog-d-image-area .date-meta h5 {
  color: var(--white);
  padding: 10px 0;
}

.blog-d-image-area .date-meta span {
  background: rgba(244, 240, 240, 1);
  font-size: 12px;
  line-height: 22px;
  font-weight: 600;
  font-family: var(--rubik-font);
  padding: 10px;
  border-radius: 0 0 5px 5px;
  color: var(--black);
}

.blog-d-author-meta {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 30px;
  gap: 20px;
}

.blog-d-author-meta .info span {
  font-size: 12px;
  line-height: 22px;
}

.blog-d-author-meta .info h5 {
  font-weight: 600;
  font-size: 16px;
}

.blog-details-section blockquote {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  font-family: var(--cormorant-font);
  text-transform: capitalize;
  color: var(--black);
  padding: 50px;
  background: rgba(238, 240, 245, 1);
  border-left: 10px solid var(--theme-color);
  border-radius: 10px;
  margin-bottom: 30px;
}

.blog-tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.blog-tags ul {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.blog-tags ul li a {
  padding: 15px 20px;
  border: 1px solid rgba(239, 225, 225, 1);
  border-radius: 50px;
  font-size: 14px;
  background: var(--white);
}

.blog-tags ul li a:hover {
  background: var(--theme-color);
  color: var(--white);
  border: 1px solid var(--theme-color);
}

.author-comments {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  margin-top: 50px;
}

.author-comments img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid rgba(224, 224, 224, 1);
}

.author-comments .content .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.author-comments .content {
  border-bottom: 1px solid rgba(239, 225, 225, 1);
  padding-bottom: 50px;
}

.submit-form-input {
  padding: 10px;
}

.submit-form-input textarea {
  height: 220px;
  resize: none;
}

.commtent-form {
  margin-left: -10px;
}

@media only screen and (max-width: 991px) {

  .commtent-form {
    padding-bottom: 30px;
  }

  .small-bd-image figure {
    padding: 0;
    padding-bottom: 20px;
  }

}

@media only screen and (max-width: 767px) {

  .author-comments {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    margin-top: 25px;
    flex-direction: column;
  }

  .author-comments .content {
    padding-bottom: 25px;
  }

  .comments-area.mt-90 {
    margin-top: 50px;
  }

  h4.title-2.mt-50.mb-50 {
    margin: 25px 0;
  }

  .commtent-form .btn-area {
    padding-top: 15px;
  }

  .blog-details-section blockquote {
    padding: 20px;
  }

  .blog-tags ul {
    gap: 10px;
  }

  .blog-tags ul li a {
    padding: 10px 15px;
    border: 1px solid rgba(239, 225, 225, 1);
    border-radius: 50px;
    font-size: 12px;
    background: var(--white);
  }

}

.sd-contact-cta {
  position: relative;
  background-repeat: no-repeat;
  background-position: bottom right;
  text-align: center;
  background: rgba(238, 240, 245, 1);
  border-radius: 10px;
  padding: 80px 50px;
}

.sd-contact-cta h4 {
  position: relative;
  font-size: 35px;
  line-height: 45px;
  margin-bottom: 20px;
}

.sd-contact-cta .icon-box {
  width: 58px;
  height: 58px;
  background: var(--color-1);
  border-radius: 50%;
  font-size: 24px;
  color: var(--white);
  padding: 17px;
  margin: 0 auto;
}

.sd-contact-cta h5 {
  font-weight: 600;
  margin: 15px;
  color: var(--theme-color);
}

.sd-contact-cta h3 {
  font-size: 50px;
  line-height: 60px;
}

.sd-contact-cta h3:hover {
  color: var(--theme-color);
}

.sd-contact-cta .shape-1 {
  position: absolute;
  top: 0;
  right: 0;
}

.sd-download {
  background: var(--theme-color);
  border-radius: 10px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.sd-download h5 {
  color: var(--white);
}

.sd-small .one {
  padding-right: 10px;
}

.sd-small .two {
  padding-left: 10px;
}

.sd-content-wrapper h3 {
  font-size: 35px;
}


.submit-form-input input,
.submit-form-input textarea,
.widget .search-box input {
  background: #EEF0F5;
}


.widget .search-box button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  font-size: 20px;
}

@media only screen and (max-width: 1399px) {

  .sd-contact-cta {
    padding: 40px 20px;
  }

  .sd-download {
    padding: 20px;
  }

  .sd-contact-cta h3 {
    font-size: 30px;
    line-height: 40px;
  }

  .sd-contact-cta h4 {
    position: relative;
    font-size: 28px;
    line-height: 38px;
    font-weight: 600;
    margin-bottom: 20px;
  }

}

@media only screen and (max-width: 991px) {

  .sd-small .one {
    padding-right: 0;
  }

  .sd-small .two {
    padding-left: 0;
  }

}

.latest-post-1 {
  border: 1px solid rgba(229, 215, 215, 1);
  border-radius: 5px;
}

.latest-post-1 .inner h4 {
  font-size: 25px;
  font-weight: 700;
}

.latest-post-1 .inner {
  padding: 35px 30px;
  background: rgba(238, 240, 245, 1);
  margin: 10px;
  border-radius: 5px;
}

.bl-post-box {
  display: flex;
  justify-content: start;
  gap: 20px;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid rgba(239, 225, 225, 1);
}

.bl-post-box span {
  font-size: 12px;
  line-height: 22px;
}

.bl-post-box h6 {
  font-family: var(--rubik-font);
  font-weight: 400;
}

.inner .bl-post-box:last-child {
  padding-bottom: 0;
  border: 0;
}

.bl-post-box img {
  width: 64px;
  height: 60px;
  border-radius: 5px;
}

.widget-title {
  font-size: 25px;
  font-weight: 600;
}

.cat-1 {
  border: 1px solid rgba(229, 215, 215, 1);
  border-radius: 5px;
}

.cat-1 .inner {
  padding: 35px 30px;
  background: rgba(238, 240, 245, 1);
  margin: 10px;
  border-radius: 5px;
}

.cat-1 .inner ul li a {
  border-bottom: 1px solid transparent;
  display: block;
  padding: 15px 0;
  color: var(--black);
}

.cat-1 .inner ul li:last-child a,
.cat-1 .inner ul li:last-child a:hover {
  border: 0;
  padding-bottom: 0;
}

.cat-1 .inner ul li a:hover {
  border-bottom: 1px solid rgba(232, 213, 213, 1);
  color: var(--theme-color);
}

@media only screen and (max-width: 1199px) {

  .blog-list-area {
    display: flex;
    grid-template-columns: auto;
    gap: 20px;
    align-items: flex-start;
    flex-direction: column;
  }

}

@media only screen and (max-width: 991px) {

  .blog-list-area {
    display: flex;
    grid-template-columns: auto;
    gap: 20px;
    align-items: flex-start;
    flex-direction: column;
  }

  .bl-pagination {
    margin-bottom: 50px;
  }

  .latest-post-1 .inner br {
    display: none;
  }

}

/*----------------------------------------*/
/* VISA DETAILS CSS START
/*----------------------------------------*/

.visa-details-section aside {
  background: #F2F2F2;
  padding: 30px 20px;
}

.visa-top h2 {
  font-size: 48px;
  line-height: 58px;
}

.visa-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.visa-top ul li {
  color: var(--black);
}

.visa-top ul li span {
  font-family: var(--cormorant-font);
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: var(--theme-color);
}

.visa-details-section blockquote {
  margin: 0;
  padding: 42px 90px 42px 60px;
  background: var(--white);
  border-left: 5px solid var(--theme-color);
  border-radius: 10px;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  font-family: var(--cormorant-font);
  box-shadow: 0px 4px 60px 0px #0000001A;
  color: var(--black);
}

.visa-details-section .work-3-process-box:before,
.visa-details-section .work-3-process-box:after {
  display: none;
}

.form-download {
  background: #D81B3D;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px;
}

.form-download h5 {
  color: var(--white);
}

@media only screen and (max-width: 1399px) {

  .visa-details-section .work-3-process-box .image-box:before {
    top: -10px;
    left: 19px;
    width: 200px;
    height: 200px;
  }

  .visa-details-section .work-3-process-box img {
    width: 180px;
    height: 180px;
  }

  .cta-3-section.style-3 {
    margin-top: 60px;
  }

}

@media only screen and (max-width: 1199px) {

  .visa-details-section .work-3-process-main {
    display: flex;
    flex-direction: column;
    gap: 35px;
  }

  .visa-details-section .work-3-process-box h5 {
    margin-top: 25px;
    margin-bottom: 5px;
  }

  .visa-details-section .work-3-process-box .image-box:before {
    left: 30px;
  }

  .faltu-widgets .content h5 {
    font-size: 21px;
    line-height: 31px;
  }

  .visa-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
    flex-direction: column;
  }

  .visa-details-section blockquote {
    padding: 30px;
    font-size: 20px;
    line-height: 30px;
  }

  .visa-details-section .primary-btn-4 {
    margin-bottom: 30px;
  }

  .form-download {
    padding: 20px;
  }

}

.country-details-section aside {
  background: #F2F2F2;
  padding: 30px 20px;
}

.coaching-widget ul.coaching-list li a img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}