.bg-image {
    background-image: linear-gradient(90deg, rgba(225, 56, 51, 0.8) 0%, rgba(29, 40, 81, 0.36) 102.55%), url('../../../public/assets/imgs/banner-1.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 80vh;
}

.hero-section {
    display: flex;
    justify-content: space-between;
}

.left {
    padding: 70px 50px 50px 50px;
}


.left .left-para {
    font-size: 3rem !important;
    font-family: 'Cormorant', serif !important;
    font-weight: 800 !important;
    color: #fff !important;
    text-align: left !important;
    max-width: 900px !important;
    line-height: 1.3;
}

.left .btn1 {
    margin-top: 15px !important;
    border: none !important;
    color: rgba(225, 56, 51, 0.8) !important;
    padding: 10px 20px !important;
    background: #fff;
    font-family: 'Cormorant', serif;
    font-weight: 800;

}

/* Right*/
.right {
    padding: 50px;
    width: 50vw;
}

.form-container {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 2px;
    padding: 30px;
    width: 30vw;
    margin-left: auto;

}

.form-header {
    font-size: 18px;
    font-family: 'Cormorant', serif;
    font-weight: 600;
    text-align: center;
    color: #000000;
}

.form-group1 {
    margin-top: 15px;
    margin-bottom: 15px;
}


.form-group1 .form-control1 {
    padding: 10px 20px !important;
    height: 40px;
    border: 1px solid rgb(211, 211, 211, 100%) !important;
    border-radius: 2px !important;
    margin-bottom: 5px !important;

}

.form-group1 .form-control1:focus {
    outline: none;
}
.form-group1 .form-control2 {
    font-family: 'Cormorant', serif !important;
    font-weight: 600 !important;
    color: #000000 !important;
    padding: 0px 20px !important;
    height: 40px !important;
    width: 100%;
    border: 1px solid rgb(211, 211, 211, 100%) !important;
    border-radius: 2px !important;
    margin-bottom: 5px !important;

}
.form-group1 .form-control2:focus {
    outline: none;
}
.form-group1 .form-control1::placeholder {
    color: #000000;
    font-family: 'Cormorant', serif;
    font-weight: 600;
}

.btn2 {
    margin-top: 10px;
    background-color: rgb(216, 27, 61, 100%);
    border: none;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    font-family: 'Cormorant', serif;
    font-weight: 600;
}

.form-footer {
    margin-top: 10px;
    font-size: 16px;
    font-family: 'Cormorant', serif;
    font-weight: 600;
}

.whatapp {
    position: absolute;
    top: 100;
    right: 100;
    z-index: 1;
}

/* Responsive Boostrap*/
@media (max-width: 990px) {
    body {
        overflow-x: hidden;
    }

    .left {
        padding: 30px;
    }

    .left .left-para {
        font-size: 2rem !important;
        max-width: 350px;
    }

    .right {
        padding: 30px;
        width: 50vw;
    }

    .form-container {
        padding: 20px;
        width: 40vw;

    }
}

@media (max-width: 774px) {
    body {
        overflow-x: hidden;
    }

    .left {
        padding: 60px 20px 0px 20px;
    }

    .left .left-para {
        font-size: 1.5rem;
        min-width: 250px;
    }

    .right {
        padding: 20px 10px 0px 10px !important;
        width: 50vw !important;
    }

    .form-container {
        padding: 10px !important;
        width: 45vw !important;

    }
}

@media (max-width: 611px) {
    body {
        overflow-x: hidden;
    }

    .hero-section {
        display: flex !important;
        flex-direction: column !important;
    }

    .left {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        margin-top: -30px !important;
    }

    .left .left-para {
        font-size: 1.1rem !important;
        line-height: 1.3 !important;
        min-width: 400px !important;
        text-align: center !important;

    }

    .right {
        padding: 20px 10px 10px 10px !important;
        width: auto !important;
    }

    .form-container {
        padding: 10px !important;
        width: auto !important;
        margin-bottom: 5px !important;
    }

    .left .btn1 {
        padding: 5px 10px !important;

    }
}