/* styles.css */

html, body, #root {
    height: 100%; /* Ensure all parent elements take up the full height of the viewport */
    margin: 0; /* Remove default margin */
  }
  
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensure App container takes up at least the full height of the viewport */
  }
  
  main {
    flex-grow: 1; /* Make sure main takes up remaining space */
  }
  
