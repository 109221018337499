#StepToFlowSection {
    background: var(--myGray);
    min-height: 70dvh;
    height: fit-content;
    width: 100%;
    background-size: 100vh;
}

#StepToFlowSection>.myContainer>h1 {
    color: #000000;
    text-transform: capitalize;
    padding: 0 10px;
    position: relative;
    width: fit-content;
    margin: 0 auto;
    font-size: 36px;
    margin-bottom: 12px;
    font-family: 'Cormorant', serif;
}

#StepToFlowSection>.myContainer>h6 {
    color: var(--myRed);
    text-transform: capitalize;
    padding: 0 10px;
    position: relative;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 12px;
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

#StepToFlowSection>.myContainer>h6::before,
#StepToFlowSection>.myContainer>h6::after {
    position: absolute;
    background-color: var(--myRed);
    height: 70%;
    display: block;
    top: 55%;
    transform: translateY(-50%);
    content: '';
    padding: 1px;
}

#StepToFlowSection>.myContainer>h6::before {
    right: 0;
}

#StepToFlowSection>.myContainer>h6::after {
    left: 0;
}

.StepToFlowSection-card22 {
    width: 320px;
    height: 320px;
}

.StepToFlowSection-card22>div {
    background-color: white;
    padding: 20px 10px;
    width: 100%;
    height: 70%;
    transition: all .4s;
    position: relative;
    margin-top: 25px;
}

.StepToFlowSection-card22 .num {
    font-family:'Cormorant', serif;
    font-size: 28px !important;
    font-weight: bold;
}

.StepToFlowSection-card22>div>i {
    position: absolute;
    clip-path: polygon(48% 20%, 0% 100%, 100% 100%);
    background-color: #fff;
    padding: 10px;
    top: -18px;
    opacity: 0;
    transition: all .1s;
    opacity: 1;
}

.StepToFlowSection-card22>div:hover {
    background-color: var(--myRed);
    cursor: pointer;
}

.StepToFlowSection-card22>div:hover i {
    background-color: var(--myRed);
    opacity: 1;
}

.StepToFlowSection-card22>div:hover h1:first-child,
.StepToFlowSection-card22>div:hover p,
.StepToFlowSection-card22>div:hover h1 {
    color: white;
}

.StepToFlowSection-card22>div>h1:first-child {
    color: var(--myRed);
    font-weight: bold;
}

.StepToFlowSection-card22>div>h1:nth-child(2) {
    font-size: 22px;
    font-weight: bold;
    margin: 10px 0;
}

.StepToFlowSection-card22>div>p {
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    color: gray;
    transition: all .4s;
    margin-top: 10px;
}

.StepToFlowSection-card22>figure {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--myRed);
    width: 90px;
    height: 90px;
}

.StepToFlowSection-card22>figure>img {
    width: 60%;
    height: 60%;
}


@media (min-width: 992px) and (max-width: 1328px) {
    .StepToFlowSection-card22 {
        display: flex;
        width: 20%;
        height: 300px;
    
    }
    .StepToFlowSection-card22>div {
        margin-top: 25px;
    }
}
