@import url('https://fonts.googleapis.com/css2?family=Days+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&display=swap');

:root {
    --myRed: rgb(216,27,61,100%);
    --myGray: #f3f3f3;
    --ofwhite: rgb(255,242,231,100%);
}

